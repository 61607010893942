import { SelectableChip } from "./SelectableChip";

export const frequencies = [
  { value: 1, label: "Daily" },
  { value: 2, label: "Weekly" },
  { value: 3, label: "Monthly" },
  { value: 4, label: "Quarterly" },
  { value: 5, label: "Yearly" },
];
export function RecommendationFrequency({ onChange, value }) {
  return (
    <div>
      <div className="text-sm font-semibold text-black mb-2">
        Recommendation Frequency
      </div>
      <div className="mt-[15px] flex flex-wrap gap-2 items-center">
        {frequencies.map((frequency) => (
          <SelectableChip
            label={frequency.label}
            selected={`${value}` == `${frequency.value}`}
            onClick={() => onChange(frequency.value)}
          />
        ))}
      </div>
    </div>
  );
}
