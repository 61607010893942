import { Button } from "../../../components/Button";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as More } from "../../../assets/icons/More.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { ReactComponent as BanIcon } from "../../../assets/icons/slash.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { UserView } from "../View/View";
import { UserDeleteConfirm } from "../components/UserDeleteConfirm";
import { api } from "../../../services/api";
import { BlockedContentModal } from "../components/BlockedContentModal";
import useBlockedByAdminUser from "./useBlockedByAdminUser";
import toast from "react-hot-toast";
import { formatPhoneNumber } from "../../../helpers/formatPhoneNumber";
import { EmptyTableView } from "../../../components/EmptyTableView";
import { ShowToast } from "../../../components/Toast";
import { UserIdWithTooltip } from "../components/UserIdWithTooltip";
import { CrownTooltip } from "../components/CrownTooltip";
import moment from "moment";
import UIPopover from "../../../components/Popover/Popover";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b"
        >
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse transition duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                  <div className="w-4 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
                <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function BlockedByAdminList({ refresh, onTotalUpdate }) {
  const {
    updateFilters,
    filters,
    isLoading,
    isFetching,
    refetch,
    users,
    total,
    loadMore,
  } = useBlockedByAdminUser();

  const [deleting, setDeleting] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);
  const [blockedContent, setBlockedContent] = useState<any>(null);
  const [user, setUser] = useState<any>(null);

  const onSortChange = (newFilters) => {
    updateFilters(newFilters);
  };

  const handleSort = (key) => {
    if (key === filters.sort_by) {
      onSortChange({
        sort_by: filters.sort_order === "desc" ? "" : key,
        sort_order:
          filters.sort_order === "asc"
            ? "desc"
            : filters.sort_order === "desc"
            ? ""
            : "asc",
      });
    } else {
      onSortChange({ sort_order: "asc", sort_by: key });
    }
  };
  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();
  // const handleDeleteUser = (user) => {
  //   setRemoveUserModal(user);
  // };
  const handleActivateUserConfirm = async (user) => {
    try {
      // api call
      // const user = deactivateUser;
      toast.loading("Unblocking user...");
      const response = await api.blockUser({ user_id: user.user_id });
      toast.dismiss();
      if (response.status === 200) {
        if (response?.data?.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch(user.user_id, true);
          refresh(undefined, undefined, true);
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleRemoveUserConfirm = async () => {
    try {
      // api call
      const user = removeUserModal;
      setDeleting(true);
      const response = await api.deleteUser({ user_id: user.user_id });
      setDeleting(false);
      if (response.status === 200) {
        if (response.data.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch();
          setRemoveUserModal(null);
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const closeReportedContent = () => {
    setBlockedContent(null);
  };

  const onRefetch = () => {
    refetch();
    refresh();
  };

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // TODO: temporary adding -1 to total due to backend issue
        if (entries[0].isIntersecting && users && users.length < total) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    if(onTotalUpdate) {
      onTotalUpdate(total);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, users, total, onTotalUpdate]);

  return (
    <>
      <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-5">
        <div className="flex items-center justify-between pl-2">
          <div className="flex items-center gap-2">
            <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
              <SearchIcon />
              <input
                type="text"
                placeholder="Search"
                className="outline-none"
                onChange={(e) => updateFilters({ keyword: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div className="mt-5 text-[14px] h-[calc(100vh-250px)] overflow-auto">
          <table className="w-full">
            <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
            <tr className="sticky top-0 z-[1]">
                  <td
                    onClick={() => handleSort("name")}
                    className="pl-5 rounded-l"
                  >
                    <div className="flex items-center cursor-pointer">
                      User Name/ID
                      {filters.sort_by === "name" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${filters.sort_order === "asc"
                            ? "rotate-[180deg]"
                            : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center">Phone number</div>
                  </td>
                  <td onClick={() => handleSort("joined_on")}>
                    <div className="flex items-center cursor-pointer">
                      Joined on
                      {filters.sort_by === "joined_on" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${filters.sort_order === "asc"
                            ? "rotate-[180deg]"
                            : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td onClick={() => handleSort("reveals_sent")}>
                    <div className="flex items-center cursor-pointer">
                      Matches initiated
                      {filters.sort_by === "reveals_sent" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${filters.sort_order === "asc"
                            ? "rotate-[180deg]"
                            : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => handleSort("last_activity")}
                      className="flex items-center cursor-pointer"
                    >
                      Last activity
                      {filters.sort_by === "last_activity" ? (
                        <ArrowRight
                          className={`sort-icon ${filters.sort_order === "asc"
                            ? "rotate-[180deg]"
                            : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td className="rounded-r"></td>
                </tr>
            </thead>
            <tbody>
              {!isLoading && !isFetching && !users?.length && (
                <EmptyTableView
                  cols={7}
                  message="No users found"
                  className="h-[calc(100vh-225px)]"
                />
              )}
              {users?.map((aclient, i) => (
                  <tr
                    key={aclient.user_id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => setUser(aclient)}
                  >
                    <td className="pl-5 name">
                      <div className="flex items-center gap-3">
                        <img
                          src={aclient.user_image}
                          className="object-cover w-10 h-10 rounded-full"
                          alt="user"
                        />
                        <div className="flex flex-col">
                          <div className="flex items-center gap-1">
                            <h5>{aclient.full_name}</h5>
                            {!!aclient.is_plus_user && <CrownTooltip />}
                          </div>
                          <UserIdWithTooltip userId={aclient.user_id} />
                        </div>
                      </div>
                    </td>
                    <td className="subscription">
                      {formatPhoneNumber(aclient.u_phone_number)}
                    </td>
                    <td className="account-manager">
                      {moment
                        .utc(aclient.date_created)
                        .local()
                        .format("MMM DD, YYYY")}
                    </td>
                    <td className="account-executive">
                      <span>{aclient.matches_initiated}</span>
                    </td>
                    {/* <td className="mrr">
                      <span>{aclient.received_count}</span>
                    </td> */}
                    <td>
                      {aclient?.last_activity_date
                        ? moment
                          .utc(aclient.last_activity_date)
                          .local()
                          .format("MMM DD,YYYY | hh:mm A")
                        : "-"}
                    </td>
                    <td onClick={stopPropagate}>
                      <UIPopover
                        trigger={
                          <span className="block p-2 border rounded-lg border-gray-270">
                            <More />
                          </span>
                        }
                      >
                        {(close) => (
                          <Dropdown
                            clientStatus={aclient.status}
                            clientId={aclient.user_id}
                            removeUser={() => {
                              close();
                              setRemoveUserModal(aclient);
                            }}
                            onActivateUser={() => handleActivateUserConfirm(aclient)}
                            onView={() => {
                              close();
                              setUser(aclient);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
              {(isLoading || isFetching) && <LoadingSkeleton />}
              <div ref={observerTarget}></div>
            </tbody>
          </table>
        </div>
      </div>
      <UserView
        open={!!user}
        onClose={() => setUser(null)}
        user={user}
        refetch={onRefetch}
      />
      <UserDeleteConfirm
        title={`Are you sure you want to remove ‘${removeUserModal?.full_name}’?`}
        open={!!removeUserModal}
        onClose={() => setRemoveUserModal(null)}
        onConfirm={handleRemoveUserConfirm}
        loading={deleting}
      />
      <BlockedContentModal
        open={!!blockedContent}
        onClose={closeReportedContent}
        data={blockedContent}
        title={"Blocked Content"}
      />
    </>
  );
}

const Dropdown = ({
  clientId,
  onActivateUser,
  removeUser,
  clientStatus,
  onView,
}) => {
  return (
    <div className="z-10 w-40 bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
            onClick={onView}
            scaleAnimated={false}
          >
            <Eye />
            View profile
          </Button>
        </li>
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-gray-100 w-full text-left !justify-start"
            onClick={onActivateUser}
            scaleAnimated={false}
          >
            <BanIcon />
            Unblock user
          </Button>
        </li>
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-gray-100 text-[#F54343] w-full text-left !justify-start"
            onClick={removeUser}
            scaleAnimated={false}
          >
            <TrashIcon />
            Remove user
          </Button>
        </li>
      </ul>
    </div>
  );
};
