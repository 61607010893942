export function SelectableChip({
  label,
  selected,
  onClick,
}: {
  label: string;
  selected: boolean;
  onClick: () => void;
}) {
  return (
    <button
      onClick={onClick}
      className={`py-2 px-3 text-sm leading-[17.92px] rounded-md hover:opacity-80 ${
        selected ? "text-white bg-black" : "text-black bg-[#E9E9E9]"
      }`}
    >
      {label}
    </button>
  );
}
