import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { ReactComponent as Ellipse } from "../../../assets/icons/ellipse.svg";

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="h-[100vh] flex flex-col bg-[rgba(242,242,242,1)]">
      <div className="absolute left-0 top-[25px] pb-[25px] w-full border-b border-[#B1B1B1]">
        <div className="relative flex items-center justify-start ml-[50px]">
          <Logo className="w-48 h-10" />
          {/* <Ellipse className="absolute -left-[18px] right-0 h-[70px] w-[165px]" /> */}
        </div>
      </div>
      <div className="flex items-center justify-center flex-1">{children}</div>
    </div>
  );
}
