import { useImageCropContext } from "../provider/ImageCropProvider";
import classNames from "classnames";

export const ZoomSlider = ({ className }) => {
  const {
    zoom,
    setZoom,
    handleZoomIn,
    handleZoomOut,
    max_zoom,
    min_zoom,
    zoom_step,
  } = useImageCropContext() as any;

  return (
    <div
      className={classNames(
        className,
        "flex items-center justify-center gap-2"
      )}
    >
      <button className="p-1 text-2xl" onClick={handleZoomOut}>
        -
      </button>
      <input
        type="range"
        name="volju"
        min={min_zoom}
        max={max_zoom}
        step={zoom_step}
        value={zoom}
        onChange={(e) => {
          setZoom(Number(e.target.value));
        }}
      />
      <button className="p-1 text-2xl" onClick={handleZoomIn}>
        +
      </button>
    </div>
  );
};
