import { TextInput } from "../../../components/TextInput";
import { useEffect, useMemo, useState } from "react";
import { Button } from "../../../components/Button";
import { api } from "../../../services/api";
import { Loading } from "../../../components/Loading/Loading";
import { ShowToast } from "../../../components/Toast";
import { Modal } from "../../../components/Modals/Modal";

export const anyTrue = (object) => {
  if (!object) return false;
  return Object.values(object).filter((e) => e).length > 0;
};

export const statusList = [
  { label: "Submitted", value: 0 },
  { label: "Approved", value: 2 },
  { label: "Live", value: 3 },
  { label: "Boosted", value: 1 },
];

export const getImage = (image: string | undefined, file: File | null) => {
  if (file) {
    return URL.createObjectURL(file);
  }
  if (image) {
    return image;
  }
};

export const ItemTypes = {
  CARD: "card",
};

export function CategoryForm({
  open,
  onClose,
  category,
  refetch,
}: {
  open: boolean;
  onClose: (refetch?: boolean) => void;
  refetch: (
    refetchId?: string,
    isDelete?: boolean,
    refetchAll?: boolean
  ) => void;
  category?: any;
}) {
  const [saving, setSaving] = useState(false);
  const [originalActivity, setOriginalActivity] = useState<any>({
    category_name: "",
  });
  const [categoryForm, setCategoryForm] = useState<any>({
    category_name: "",
  });

  const handleSubmit = async (categoryForm: any) => {
    console.log(categoryForm);
    if (!categoryForm.category_name) {
      return;
    }
    let payload: any = {};
    if (categoryForm.id) {
      payload.id = `${categoryForm.id}`;
      payload = {
        ...payload,
        old_category_name: originalActivity.category_name,
        new_category_name: categoryForm.category_name,
      };
    } else {
      payload = {
        ...payload,
        category_name: categoryForm.category_name,
      };
    }
    try {
      setSaving(true);
      const API = categoryForm.id ? api.updateCategory : api.addCategory;
      const response = await API(payload);
      setSaving(false);
      console.log("ddd", response.data);
      if ([200, 201].includes(response.status) && response?.data?.status) {
        refetch("", false, true);
        console.log("ddd2", response.data);
        if (!categoryForm.id && response?.data?.category_id) {
          // set id
          setCategoryForm((prev) => ({
            ...prev,
            id: response?.data?.category_id,
          }));
        }
        onClose(true);
        ShowToast({
          type: "success",
          message: response?.data?.message,
        });
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      setSaving(false);
      ShowToast({
        type: "error",
        message: "Failed to save",
      });
    }
  };

  useEffect(() => {
    if (open) {
      setCategoryForm({ ...category });
      setOriginalActivity({ ...category });
    }
  }, [open, category]);

  const isObjectChanged = useMemo(() => {
    return JSON.stringify(categoryForm) !== JSON.stringify(originalActivity);
  }, [categoryForm, originalActivity]);

  if (!open) return null;

  const isValidate = (form = categoryForm) => {
    console.log({ form });
    return form.category_name;
  };

  console.log(categoryForm, isValidate());

  return (
    <Modal
      title={!categoryForm?.id ? "Add Hashtag" : "Edit Hashtag"}
      open={open}
      onClose={saving ? () => { } : onClose}
      showCloseButton={true}
      contentClassName="[&_h1]:text-xl max-h-[50vh] [&_.close]:w-5 [&_.close]:h-5 !max-w-[484px] [&>.header]:mb-2"
    >
      <div className="flex flex-col pt-5">
        <div className="flex-1 w-full flex flex-col gap-1.5 mb-4">
          <div className="opacity-80 text-black text-sm font-normal leading-[17.92px]">
          </div>
          <TextInput
            type="text"
            placeholder="Enter hashtag"
            value={categoryForm.category_name}
            className="w-full"
            onChange={(e) =>
              setCategoryForm((form) => ({
                ...form,
                category_name: e.target.value,
              }))
            }
          />
        </div>

        <div className="flex justify-end w-full">
          <Button
            variant="primary"
            className="!rounded-full !py-2.5 w-[130px] !font-bold !text-base tracking-[-0.24px] text-white"
            onClick={() => handleSubmit(categoryForm)}
            disabled={saving || !isValidate() || !isObjectChanged}
          >
            {!categoryForm?.id ? "Add" : "Save"}
            {saving && <Loading />}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
