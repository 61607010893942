import { Loading } from "../Loading/Loading";

type Props = {
  variant?: "primary" | "outline" | "link" | "icon" | "custom";
  loading?: boolean;
  scaleAnimated?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
export function Button({
  variant = "primary",
  className,
  loading,
  scaleAnimated = false,
  ...props
}: Props) {
  return (
    <button
      className={`relative transition-all duration-200 ${
        scaleAnimated ? "transition duration-75 hover:scale-105" : ""
      } rounded-md text-sm text-black font-medium leading-normal py-3 px-5 flex gap-1.5 items-center justify-center
      ${
        variant === "outline"
          ? "border border-black hover:border-black/50 hover:bg-[rgba(0,0,0,0.01)] !p-[1px]"
          : ""
      }
      ${
        variant === "primary"
          ? "text-white bg-black hover:bg-blackButtonHover"
          : ""
      }
      ${variant === "custom" ? "bg-black" : ""}
      ${variant === "link" ? "bg-transparent" : ""}
      ${
        props.disabled
          ? variant === "primary"
            ? "!bg-none opacity-30 text-[#FFFFFF80] cursor-not-allowed"
            : "cursor-not-allowed opacity-30"
          : ""
      }
      ${loading ? "opacity-70 cursor-not-allowed" : ""}
      ${className || ""}
    `}
      {...props}
    >
      <>
        {props.children}
        {loading && <Loading />}
      </>
    </button>
  );
}
