import { useEffect, useState } from "react";
import { ReactComponent as Star } from "../../../assets/icons/star.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as DownIcon } from "../../../assets/icons/chevron-down.svg";
import { priceMapper } from "../../../helpers/priceMapper";

function ListItem({ place, onUnSelectPlace }) {
  return (
    <div className="flex justify-between gap-2 py-[14px]  border-b border-b-[#DDD]">
      <div className="text-[#2A2B2F] text-sm font-semibold">
        {place?.displayName?.text}
      </div>
      <div className="flex items-center gap-1.5 ">
        {(place?.rating || place?.priceLevel) && (
          <div className="pl-1.5 pr-2 py-1 bg-[#F2F2F2] rounded justify-start items-center gap-1.5 flex">
            {place?.rating && (
              <div className="rounded-3xl justify-start items-center gap-1 flex">
                <Star />
                <div className="text-neutral-800 text-sm font-normal ">
                  {place?.rating}
                </div>
              </div>
            )}
            {place?.priceLevel && (
              <>
                {place?.rating && (
                  <div className="h-2 border-l border-stone-300"></div>
                )}
                <div className="text-neutral-800 text-sm font-normal ">
                  {priceMapper(place?.priceLevel)}
                </div>
              </>
            )}
          </div>
        )}
        <button onClick={() => onUnSelectPlace(place)}>
          <CloseIcon className="w-[13px] h-[13px]" />
        </button>
      </div>
    </div>
  );
}
function List({ selectedPlaces, onUnSelectPlace }) {
  return (
    <div className="w-full max-h-[334px] overflow-auto shadow-[0px_4px_28px_0px_rgba(0,0,0,0.13)] absolute top-[60px] px-[18px] py-1.5 border bg-white rounded-md border-black/40">
      {selectedPlaces.map((place) => (
        <ListItem
          key={place.id}
          place={place}
          onUnSelectPlace={onUnSelectPlace}
        />
      ))}
    </div>
  );
}
export function SelectedPlaces({
  selectedPlaces,
  setSelectedPlaces,
}: {
  selectedPlaces: any[];
  setSelectedPlaces: any;
}) {
  const [expanded, setExpanded] = useState(false);

  const handleUnSelectAll = () => {
    setSelectedPlaces([]);
  };
  const unSelectPlace = (place) => {
    setSelectedPlaces(selectedPlaces.filter((e) => e.id !== place.id));
  };

  useEffect(() => {
    // Close the dropdown when the user clicks outside
    const handleClick = (e) => {
      // check if element is not in the selected places dropdown
      const element = document.querySelector(".selected-places");
      if (element && !element.contains(e.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="selected-places relative my-2.5 mx-3">
      <div
        role="button"
        onClick={() => setExpanded(!expanded)}
        className="w-full px-4 py-3.5 bg-zinc-100 rounded-md border border-black border-opacity-30 justify-between items-center flex"
      >
        <div className="justify-start items-center gap-2 flex">
          <div className="text-neutral-800 text-sm font-bold">
            Selected {selectedPlaces.length}
          </div>
          <button
            className="text-neutral-800 text-sm font-normal"
            onClick={handleUnSelectAll}
          >
            Un-select all
          </button>
        </div>
        <DownIcon className={`w-6 h-6 ${expanded ? "rotate-180" : ""}`} />
      </div>
      {expanded && (
        <List selectedPlaces={selectedPlaces} onUnSelectPlace={unSelectPlace} />
      )}
    </div>
  );
}
