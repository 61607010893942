import { useState } from "react";
import { FullModal } from "../../../components/Modals/FullModal";
import { BlockedByUserList } from "./BlockedByUsers";
import { BlockedByAdminList } from "./BlockedByAdmin";

export const blockedUserType = {
  USER_BLOCKED: "user_blocked",
  ADMIN_BLOCKED: "admin_blocked",
};

export function BlockedUserList({ open, onClose, refresh }) {
  const [activeTab, setActiveTab] = useState<string>(
    blockedUserType.ADMIN_BLOCKED
  );
  const [adminBlockedCount, setAdminBlockedCount] = useState(0);
  const [userBlockedCount, setUserBlockedCount] = useState(0);

  const handleAdminBlockedTotalUpdate = (totalUsers) => {
    setAdminBlockedCount(totalUsers);
  };

  const handleUserBlockedTotalUpdate = (totalUsers) => {
    setUserBlockedCount(totalUsers);
  };

  return (
    <FullModal open={open} onClose={onClose}>
      <div className="w-[1100px] mx-auto">
        <div className="mb-6 mt-1 flex items-center justify-between flex-wrap gap-4">
          <h1 className="text-black text-[32px] font-bold">Blocked users</h1>
          <div className="rounded-[10px] bg-[#D8DED7] p-1 w-fit flex items-center gap-1">
            <button
              onClick={() => setActiveTab(blockedUserType.ADMIN_BLOCKED)}
              className={`${
                activeTab === blockedUserType.ADMIN_BLOCKED
                  ? "bg-white"
                  : "bg-transparent hover:bg-white/60"
              } rounded-md px-4 py-2 text-base text-[#33363F]`}
            >
              Blocked by admin <span>({adminBlockedCount})</span>
            </button>
            <button
              onClick={() => setActiveTab(blockedUserType.USER_BLOCKED)}
              className={`${
                activeTab === blockedUserType.USER_BLOCKED
                  ? "bg-white"
                  : "bg-transparent hover:bg-white/60"
              } rounded-md px-4 py-2 text-base text-[#33363F]`}
            >
              Blocked by users <span>({userBlockedCount})</span>
            </button>
          </div>
        </div>
        {activeTab === blockedUserType.USER_BLOCKED && <BlockedByUserList onTotalUpdate={handleUserBlockedTotalUpdate} />}
        {activeTab === blockedUserType.ADMIN_BLOCKED && (
          <BlockedByAdminList refresh={refresh} onTotalUpdate={handleAdminBlockedTotalUpdate} />
        )}
      </div>
    </FullModal>
  );
}
