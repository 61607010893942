import { ReactComponent as Star } from "../../../assets/icons/star.svg";
import { ReactComponent as Location } from "../../../assets/icons/location-pin.svg";
import { priceMapper } from "../../../helpers/priceMapper";
import { ImportedTooltip } from "../../activities/components/AddedPlaces";

export function PlaceItemView({
  onClick,
  checked,
  place,
  shouldDisable = false,
  type = "places",
  prefix = "",
}: {
  onClick: () => void;
  checked: boolean;
  place: any;
  shouldDisable?: boolean;
  type?: string;
  prefix?: string;
}) {
  return (
    <div
      role="button"
      onClick={place?.is_existing && shouldDisable ? undefined : onClick}
      className={`flex gap-2.5 items-start py-[18px] border-b border-b-[#DDD] ${
        place?.is_existing && shouldDisable ? "opacity-50" : ""
      }`}
    >
      <div>
        <input
          type="checkbox"
          id="place"
          name="place"
          className="w-5 h-5 border border-[#8D8E92] accent-black rounded-md"
          checked={checked}
        />
      </div>
      <div className="flex-1 flex flex-col gap-2.5">
        <div className="flex items-center gap-2">
          <div className="text-[#2A2B2F] text-sm font-semibold">
            {place?.displayName?.text}
          </div>
          {type === "activity" && place.is_existing && (
            <ImportedTooltip place={place} prefix={prefix} />
          )}
        </div>

        <div className="flex items-start gap-1">
          <Location className="w-[18px] h-[18px] mt-[2px]" />
          <div className="text-[#555] text-sm font-normal flex-1">
            {place.formattedAddress}
          </div>
        </div>
      </div>
      {type !== "activity" && (
        <div>
          {(place?.rating || place?.priceLevel) && (
            <div className="pl-1.5 pr-2 py-1 bg-[#F2F2F2] rounded justify-start items-center gap-1.5 flex">
              {place?.rating && (
                <div className="rounded-3xl justify-start items-center gap-1 flex">
                  <Star />
                  <div className="text-neutral-800 text-sm font-normal ">
                    {place?.rating}
                  </div>
                </div>
              )}
              {place?.priceLevel && (
                <>
                  {place?.rating && (
                    <div className="h-2 border-l border-stone-300"></div>
                  )}
                  <div className="text-neutral-800 text-sm font-normal ">
                    {priceMapper(place?.priceLevel)}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
