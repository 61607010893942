import { Routes, Route, Navigate } from "react-router-dom";
import { Main } from "./main";
import { Login } from "./auth";
import { Dashboard } from "./dashboard/Dashboard";
import { UserList } from "./users/List/List";
import { PlacesList } from "./places/Places";
import SuspenseWrapper from "../components/SuspenseWrapper";
import { DataCollection } from "./data-import/DataCollection";
import { ActivityList } from "./activities/Activities";
import { AppFilters } from "./app-filters/AppFilters";
import { CategoryList } from "./categories/Categories";

const RoutesList = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<Main />}>
        <Route
          // index
          path="/dashboard"
          element={
            <SuspenseWrapper>
              <Dashboard />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/users"
          element={
            <SuspenseWrapper>
              <UserList />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/categories"
          element={
            <SuspenseWrapper>
              <CategoryList />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/places"
          element={
            <SuspenseWrapper>
              <PlacesList />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/experiences"
          element={
            <SuspenseWrapper>
              <ActivityList />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/data-import"
          element={
            <SuspenseWrapper>
              <DataCollection />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/app-filters"
          element={
            <SuspenseWrapper>
              <AppFilters />
            </SuspenseWrapper>
          }
        />
        <Route
          index
          element={
            // if loggged in redirect to dashboard
            <Navigate to="/login" />
          }
        />
      </Route>
    </Routes>
  );
};
export default RoutesList;
