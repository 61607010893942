import { HighlightCard } from "../../../dashboard/components/HighlightCard";

import { ReactComponent as ChevronRight } from "../../../../assets/icons/Chevrons.svg";
import { useState } from "react";
import { AllInvitesList } from "./AllInvitesList";
import { api } from "../../../../services/api";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../../../../components/Loading/Loading";

function AnalyticsCard({
  onClick,
  label,
  value,
}: {
  onClick: any;
  label: any;
  value: any;
}) {
  return (
    <button
      onClick={onClick}
      className="flex-1 [&_.info-view]:text-left [&_.value]:!text-[28px]"
    >
      <HighlightCard
        icon={null}
        label={
          <div className="flex items-center">
            <span className="font-normal">{label}</span>
            <ChevronRight className="mt-[2px]" />
          </div>
        }
        value={value}
        // value={formatNumber(Number(dashboardData?.total_users))}
        iconClassName="bg-[linear-gradient(125deg,#00FFB3_-2.31%,#F0FF44_97.12%)]"
        className="cursor-pointer hover:shadow-[rgba(0,71,28,0.12)_0px_7px_16px]"
      />
    </button>
  );
}
export function Analytics({ user_id }: { user_id: string }) {
  const [showAnalyticsList, setShowAnalyticsList] = useState<string>("all");

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["user-analytics", user_id],
    queryFn: () => api.getUserAnalytics({ user_id }),
  });

  const analytics = data?.data;
  if (isLoading)
    return (
      <div className="flex justify-center items-center h-[calc(100vh-350px)]">
        <Loading />
      </div>
    );
  return (
    <div>
      <div className="flex gap-4 items-center">
        <AnalyticsCard
          onClick={() => setShowAnalyticsList("all")}
          label="Invites received"
          value={analytics?.total_invites_received || 0}
        />
        <AnalyticsCard
          onClick={() => setShowAnalyticsList("skipped")}
          label="Invites skipped"
          value={analytics?.skip_count || 0}
        />
        <AnalyticsCard
          onClick={() => setShowAnalyticsList("accepted")}
          label="Invites Accepted"
          value={analytics?.accepted_count || 0}
        />
        <AnalyticsCard
          onClick={() => setShowAnalyticsList("became_plan")}
          label="Invites became plan"
          value={analytics?.plans_count || 0}
        />
      </div>
      <div className="flex gap-4 mt-4 w-full">
        <AllInvitesList
          user_id={user_id}
          onFilterChange={(filter: string) => setShowAnalyticsList(filter)}
          filter={showAnalyticsList}
        />
      </div>
    </div>
  );
}
