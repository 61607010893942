import { createFilter } from "react-select";
import apiClient from "./axios";
import moment from "moment";

export const api = {
  login: (data: any) => {
    return apiClient.post("/admin/auth/login", data);
  },
  getAdminDetails: () => {
    return apiClient.get("/admin/me");
  },
  getDashboard: () => {
    return apiClient.post("/admin/dashboard").then((res) => res.data);
  },
  getDashboardCharts: ({ type }) => {
    return apiClient
      .get(`/admin/dashboard-chart?type=${type}`)
      .then((res) => res.data);
  },
  getStackedChart: ({ type }: { type: string }) => {
    return apiClient
      .get(`/admin/stacked-bar-chart?type=${type}`)
      .then((res) => res.data);
  },
  getPieChart: ({ type }: { type: string }) => {
    return apiClient
      .get(`/admin/pie-chart?type=${type}`)
      .then((res) => res.data);
  },
  getUsersV2: (data, signal?: any) => {
    const url = "/admin/users-v2";

    const payload: any = {
      page: data.page,
      limit: data.limit,
      type: data.type,
    };
    if (data.keyword) {
      payload.search = data.keyword;
    }
    if (data.sort_order && data.sort_by) {
      payload.sort_order = data.sort_order;
      payload.sort_by = data.sort_by;
    }
    // if (data.is_plus_user) {
    //   payload.is_plus_user = true;
    // }
    if (data.filter) {
      const filter: any = {};
      if (data?.filter?.date_joined_start && data?.filter?.date_joined_end) {
        filter.date_joined_start = moment(data.filter.date_joined_start).format(
          "YYYY-MM-DD"
        );
        filter.date_joined_end = moment(data.filter.date_joined_end).format(
          "YYYY-MM-DD"
        );
      }
      [
        "received_count_min",
        "received_count_max",
        "skipped_count_min",
        "skipped_count_max",
        "accepted_count_min",
        "accepted_count_max",
        "two_accepted_count_min",
        "two_accepted_count_max",
        "friends_count_min",
        "friends_count_max",
        "friend_of_friend_count_min",
        "friend_of_friend_count_max",
        "user_chat_count_min",
        "user_chat_count_max",
      ].forEach((field) => {
        // check if value is valid number
        if (
          data?.filter?.[field]?.toString()?.length > 0 &&
          !isNaN(Number(data.filter[field]))
        ) {
          filter[field] = Number(data.filter[field]);
        }
      });

      payload.filter = filter;
    }
    return apiClient.post(url, payload, { signal }).then((res) => res.data);
  },
  downloadUsersV2CSV: (data: any) => {
    const payload: any = {
      page: data.page,
      limit: data.limit,
      type: data.type,
    };
    if (data.keyword) {
      payload.search = data.keyword;
    }
    if (data.sort_order && data.sort_by) {
      payload.sort_order = data.sort_order;
      payload.sort_by = data.sort_by;
    }
    // if (data.is_plus_user) {
    //   payload.is_plus_user = true;
    // }
    if (data.filter) {
      const filter: any = {};
      if (data?.filter?.date_joined_start && data?.filter?.date_joined_end) {
        filter.date_joined_start = moment(data.filter.date_joined_start).format(
          "YYYY-MM-DD"
        );
        filter.date_joined_end = moment(data.filter.date_joined_end).format(
          "YYYY-MM-DD"
        );
      }
      [
        "received_count_min",
        "received_count_max",
        "skipped_count_min",
        "skipped_count_max",
        "accepted_count_min",
        "accepted_count_max",
        "two_accepted_count_min",
        "two_accepted_count_max",
        "friends_count_min",
        "friends_count_max",
        "friend_of_friend_count_min",
        "friend_of_friend_count_max",
        "user_chat_count_min",
        "user_chat_count_max",
      ].forEach((field) => {
        // check if value is valid number
        if (
          data?.filter?.[field]?.toString()?.length > 0 &&
          !isNaN(Number(data.filter[field]))
        ) {
          filter[field] = Number(data.filter[field]);
        }
      });

      payload.filter = filter;
    }
    return apiClient.post("/admin/users-v2-download-csv", payload);
  },
  getUsers: (data: any) => {
    console.log("data", data);
    let url = `/admin/users?page=${data.page}&limit=${data.limit}&type=${data.type}`;
    if (data.keyword) {
      url += `&search=${data.keyword}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    if (data.is_plus_user) {
      url += `&is_plus_user=true`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getUserAnalytics: (data: any) => {
    return apiClient
      .get(`/admin/user-analytics?user_id=${data.user_id}`)
      .then((res) => res.data);
  },
  getUserAnalyticsData: (data: any) => {
    const payload: any = {
      type: data.type,
      user_id: data.user_id,
      page: data.page,
      limit: data.limit,
    };

    if (data.keyword) {
      payload.keyword = data.keyword;
    }

    return apiClient
      .post(`/admin/user-analytics-data`, payload)
      .then((res) => res.data);
  },
  getPlaces: (data: any) => {
    let url = `/admin/places?page=${data.page}&limit=${data.limit}&type=${data.type}`;
    if (data.keyword) {
      url += `&search=${data.keyword}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getBlockedUsersByAdmin: (data: any) => {
    let url = `/admin/user/blocked-by-admin?page=${data.page}&limit=${data.limit}`;
    if (data.keyword) {
      url += `&search=${data.keyword}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  updateUser: (data: any) => {
    ///admin/user/profile/update
    return apiClient.put("/admin/user/profile/update", data);
  },
  updatePlace: (data: any) => {
    ///admin/place/update
    return apiClient.put("/admin/place/update", data);
  },
  createPlace: (data: any) => {
    ///admin/place/create
    return apiClient.post("/admin/place/create", data);
  },
  addPlace: (data: any) => {
    ///admin/place/update
    return apiClient.put("/admin/place/update", data);
  },
  getPlacesSuggestions: (data: any) => {
    // admin/place-suggestion
    let url = `/admin/place-suggestion`;
    return apiClient
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data);
  },
  deletePlaceSuggestion: (data: any) => {
    // admin/place-add-status/update
    let url = `/admin/place-add-status/update`;
    return apiClient.delete(url, {
      data,
    });
  },
  addPlaceUrls: (data: any) => {
    return apiClient.post("admin/place/data", data);
  },
  getPlaceHistory: (data: any) => {
    //admin/place-add/history
    const params: any = {
      filter: data.type,
      search: data.keyword,
    };
    if (data.sort_order && data.sort_by) {
      params.sort_order = data.sort_order;
      params.sort_by = data.sort_by;
    }
    return apiClient
      .post("admin/place-add/history", params)
      .then((res) => res.data);
  },
  getPlace: (id: string) => {
    ///admin/place/{place_id}
    return apiClient.get(`/admin/place/${id}`).then((res) => res.data);
  },
  getPlaceDetails: (id: string) => {
    ///admin/place/{place_id}
    return apiClient.get(`/admin/place-details/${id}`).then((res) => res.data);
  },
  blockUser: (data: { user_id: string }) => {
    return apiClient.post("/admin/block-user", data);
  },

  unblockUser: (data: { user_id: string }) => {
    return apiClient.post("/admin/unblock-user", data);
  },
  deleteUser: (data: { user_id: string }) => {
    return apiClient.post("/admin/delete-user", data);
  },
  deletePlace: (data: { id: string }) => {
    return apiClient.post("/admin/place/delete-place", data);
  },
  getUserInfo: (userId: string) => {
    return apiClient
      .get(`/admin/user-info?id=${userId}`)
      .then((res) => res.data);
  },
  getUserMatches: (userId: string) => {
    let url = `/admin/user?id=${userId}`;
    return apiClient.get(url).then((res) => res.data);
  },
  getEmptyStates: (params: any) => {
    return apiClient
      .get(
        `/admin/reveals/empty-states?page=${params.page}&limit=${params.limit}`
      )
      .then((res) => res.data);
  },
  addEmptyState: (data: any) => {
    return apiClient.post("/admin/reveals/empty-states", data);
  },
  updateEmptyState: (data: any) => {
    return apiClient.put("/admin/reveals/empty-states", data);
  },
  deleteEmptyState: (id: string) => {
    return apiClient.delete(`/admin/reveals/empty-states/${id}`);
  },
  getRevealDetails: (id: string) => {
    return apiClient.get(`/admin/reveals/${id}`).then((res) => res.data);
  },
  getCategories: (params: any) => {
    // /admin/categories

    let url = `/admin/categories?page=${params.page}&limit=${params.limit}`;
    if (params.sort_order) {
      url += `&sort_order=${params.sort_order}`;
    }
    if (params.search) {
      url += `&search=${params.search}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  addCategory: (data: any) => {
    return apiClient.post("/admin/categories/create", data);
  },
  updateCategory: (data: any) => {
    return apiClient.put("/admin/categories/update", data);
  },
  deleteCategory: (id: string) => {
    return apiClient.delete(`/admin/categories/delete/${id}`);
  },
  addQuestion: (data: any) => {
    return apiClient.post("/admin/reveals/categories/question", data);
  },
  updateQuestion: (data: any) => {
    return apiClient.put("/admin/reveals/categories/question", data);
  },
  deleteQuestion: (id: string) => {
    return apiClient.delete(`/admin/reveals/categories/question/${id}`);
  },
  getPlaceCategories: ({
    page = 1,
    limit = 10000,
    sort_order = "",
    search = "",
  }: {
    page?: number;
    limit?: number;
    sort_order?: string;
    search?: string;
  }) => {
    let url = `/admin/place/categories?page=${page}&limit=${limit}`;
    if (sort_order) {
      url += `&sort_order=${sort_order}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getPlaceActivities: () => {
    return apiClient.get("/admin/place/activities").then((res) => res.data);
  },
  getActivity: (activityId: string) => {
    let url = `/admin/activities/${activityId}`;
    return apiClient.get(url).then((res) => res.data);
  },
  createActivity: (data: any, autoSave = false) => {
    let url = `/admin/activities/create`;
    if (autoSave) {
      url = `${url}?autoSave=true`;
    }
    return apiClient.post(url, data);
  },
  updateActivity: (data: any, autoSave = false) => {
    let url = "/admin/activities/update";
    if (autoSave) {
      url = `${url}?autoSave=true`;
    }
    return apiClient.put(url, data);
  },
  deleteActivity: (id: string) => {
    const url = "/admin/activities/delete";
    return apiClient.delete(url, {
      data: {
        id: id.toString(),
      },
    });
  },
  fetchActivities: (data: any) => {
    let url = `/admin/fetch-experiences`;
    const payload: any = {
      ...data,
      filter: {
        ...data.filter,
        category: data?.filter?.category?.map((item: any) => item.label),
      },
    };
    if (data.keyword) {
      payload.search = data.keyword;
    }
    // if (data.keyword) {
    //   url += `&search=${data.keyword}`;
    // }
    // if (data.filter_value?.length > 0) {
    //   if (data.filter_by) {
    //     url += `&filter=${data.filter_by}`;
    //   }
    //   url += `&value=${(data.filter_value || []).join(",")}`;
    // }
    // if (data.sort_order && data.sort_by) {
    //   url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    // }
    return apiClient.post(url, payload).then((res) => res.data);
  },
  downloadActivities: (data: any) => {
    // /admin/fetch-experiences-download-csv
    const payload: any = {
      ...data,
      filter: {
        ...data.filter,
        category: data?.filter?.category?.map((item: any) => item.label),
      },
    };
    if (data.keyword) {
      payload.search = data.keyword;
    }
    return apiClient.post("/admin/fetch-experiences-download-csv", payload);
  },
  getActivities: (data: any) => {
    let url = `/admin/activities?page=${data.page}&limit=${data.limit}`;
    if (data.keyword) {
      url += `&search=${data.keyword}`;
    }
    if (data.filter_value?.length > 0) {
      if (data.filter_by) {
        url += `&filter=${data.filter_by}`;
      }
      url += `&value=${(data.filter_value || []).join(",")}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getImageUpload: (data: {
    folder: string;
    file_name: string;
    content_type: string;
  }) => {
    return apiClient
      .post("/general/image/upload", data)
      .then((res) => res.data);
  },
  getFilters: (data: any) => {
    return apiClient
      .get(`/admin/app-filters?type=${data.type}`)
      .then((res) => res.data);
  },
  createFilter: (data: any) => {
    const url = "/admin/app-filters/create";
    return apiClient.post(url, data);
  },
  updateFilter: (data: any) => {
    const url = "/admin/app-filters/update";
    return apiClient.put(url, data);
  },
  deleteFilter: (id: string, type: string) => {
    const url = `/admin/app-filters/delete/${id}/${type}`;
    return apiClient.delete(url);
  },
  downloadImage: (url: string) => {
    return apiClient
      .get(`/general/download-image?downloadUrl=${url}`, {
        responseType: "blob",
      })
      .then((res) => res.data);
  },
  updateV2: (data: any) => {
    return apiClient.put("/admin/activities/update-v2", data);
  },
  // /general/image/upload
};
