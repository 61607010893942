export function FilterTypeTabs({
  selectedTab,
  onChange,
}: {
  selectedTab: string;
  onChange: any;
}) {
  return (
    <div className="h-[51px] p-1 bg-[#efefef] rounded-xl justify-start items-center inline-flex">
      <button
        type="button"
        onClick={() => onChange("user_preferences")}
        className={`px-[18px] py-3 ${selectedTab === "user_preferences"
            ? "bg-white text-black"
            : "text-black/50"
          } text-base font-medium leading-tight rounded-lg justify-center items-center gap-2.5 flex`}
      >
        User Preferences
      </button>
      <button
        type="button"
        onClick={() => onChange("explore_page_filters")}
        className={`px-[18px] py-3 ${selectedTab === "explore_page_filters"
            ? "bg-white text-black"
            : "text-black/50"
          } text-base font-medium leading-tight rounded-lg justify-center items-center gap-2.5 flex`}
      >
        Create Invites
      </button>
    </div>
  );
}
