import { useState } from "react";
import DatePickerComponent from "react-datepicker";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

export function TimePicker({
  value,
  onChange,
  showIcon = true,
  className = "",
  minDate,
  disabled = false,
  placeholder,
}: {
  value: any;
  onChange: (value: any) => void;
  showIcon?: boolean;
  className?: string;
  minDate?: any;
  disabled?: boolean;
  placeholder?: string;
}) {
  const filterPassedTime = (time) => {
    if (!minDate) return true;
    const currentDate = new Date(minDate);
    const selectedDate = new Date(
      moment(moment(new Date(time)).format("hh:mmA"), "hh:mmA").toISOString()
    );
    return currentDate.getTime() < selectedDate.getTime();
  };
  return (
    <div className="date-picker">
      <DatePickerComponent
        onChange={onChange}
        selected={value}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        dateFormat="h:mm aa"
        minDate={minDate}
        filterTime={filterPassedTime}
        disabled={disabled}
        placeholderText={placeholder}
        className={`!outline-none w-[84px] h-[33px] bg-ultraLightGray focus:bg-white rounded-lg border border-lightGray focus-within:border-grey2 focus:border-grey2 p-3 text-base text-black [&>div]:!border-none ${className}`}
      />
    </div>
  );
}
