import { useEffect, useState } from "react";
import { useDebounce } from "../../../services/useDebounce";
import { api } from "../../../services/api";
import { ShowToast } from "../../../components/Toast";
const LIMIT = 25;

function useAnalytics({
  type = "all",
  user_id,
}: {
  type: string;
  user_id: string;
}) {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: LIMIT,
  });
  const [state, setState] = useState<any>({
    // page: 1,
    keyword: "",
    // status: "active",
    limit: LIMIT,
    sort_by: "",
    sort_order: "",
    type,
    user_id,
  });

  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const debouncedFilters = useDebounce(state, 500);

  const fetchUsers = async (
    params,
    pageInfo = pagination,
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    try {
      if (!params.user_id) {
        return;
      }
      setIsLoading(true);
      const response = await api.getUserAnalyticsData({
        ...params,
        ...pageInfo,
      });
      const data = response?.data?.data;
      const total = response?.data?.total_count;
      setIsLoading(false);
      // console.log("data", data, pageInfo, params);
      // filter deleted question
      if (isDeleted && refetchId) {
        setData((prev) => prev.filter((item) => item.user_id !== refetchId));
      } else if (refetchId) {
        setData((prev) => [
          ...prev.filter((item) => item.user_id !== refetchId),
          ...data,
        ]);
      } else if (refetchAll) {
        const newData: any[] = [];
        // refetch all pages
        for (let i = 1; i <= pageInfo.page; i++) {
          const response = await api.getUserAnalyticsData({
            ...params,
            page: i,
          });
          const data = response?.data?.data;
          newData.push(...data);
        }
        setData(newData);
      } else {
        if (pageInfo.page === 1) {
          setData([...data]);
        } else {
          setData((prev) => [...prev, ...data]);
        }
      }
      setTotal(total);
    } catch (err) {
      setIsLoading(false);
      ShowToast({
        type: "error",
        message: "Failed to fetch users",
      });
    }
  };

  const refetch = (
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    fetchUsers(debouncedFilters, pagination, refetchId, isDeleted, refetchAll);
  };

  // const { data, isLoading, isFetching, refetch } = useQuery(
  //   [
  //     `users-${type}`,
  //     debouncedFilters.page,
  //     debouncedFilters.keyword,
  //     debouncedFilters.type,
  //     debouncedFilters.is_plus_user,
  //     //   debouncedFilters.status,
  //     debouncedFilters.limit,
  //   ],
  //   () => api.getUsers(state),
  //   {
  //     enabled: !!debouncedFilters,
  //     refetchOnMount: true,
  //     refetchOnWindowFocus: false,

  //     // keepPreviousData: true,
  //   }
  // );

  const loadMore = () => {
    // update page only we have data
    if (data?.length > 0) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      fetchUsers(debouncedFilters, {
        ...pagination,
        page: pagination.page + 1,
      });
    }
  };

  const handleFilters = (updates: any) => {
    // update filters & reset to first page
    console.log("updates", updates);
    setIsLoading(true);
    setTimeout(() => {
      setData([]);
    }, 0);
    setPagination((prev) => ({ ...prev, page: 1 }));
    setState((prev) => ({ ...prev, ...updates }));
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, type }));
  }, [type]);

  useEffect(() => {
    console.log(debouncedFilters, "111");
    fetchUsers(debouncedFilters);
  }, [debouncedFilters]);

  const users: any[] = data || [];
  return {
    users,
    totalPages: Math.ceil(total / LIMIT),
    total,
    isLoading: isLoading,
    isFetching,
    filters: state,
    loadMore,
    refetch,
    updateFilters: (updates) => handleFilters(updates),
    reset: () => {
      setData([]);
      setState({
        page: 1,
        keyword: "",
        // status: "active",
        limit: LIMIT,
        sort_by: "",
        sort_order: "",
        type,
        user_id,
      });
    },
  };
}

export default useAnalytics;
