import { useMemo } from "react";

export const tagsToSlice = (tags) => {
  // Maximum 40 characters combined all tags
  // Show as much as possible tags until 40 characters
  let numberOfTags = 0;
  let totalLength = 0;
  const windowWidth = window.innerWidth;
  const LengthLimit = () => {
    console.log("windowWidth", windowWidth);
    switch (true) {
      case windowWidth <= 1440:
        return 50;
      case windowWidth <= 1600:
        return 75;
      case windowWidth <= 1920:
        return 100;
      default:
        return 50;
    }
  };
  const limit = LengthLimit();
  for (let i = 0; i < tags.length; i++) {
    totalLength += tags[i].length;
    if (totalLength > limit) {
      break;
    }
    numberOfTags++;
  }
  return numberOfTags;
};
