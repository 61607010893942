import { TextInput } from "../../../components/TextInput";
import { useEffect, useMemo, useState } from "react";
import { Button } from "../../../components/Button";
import { api } from "../../../services/api";
import { Loading } from "../../../components/Loading/Loading";
import { ShowToast } from "../../../components/Toast";
import { Modal } from "../../../components/Modals/Modal";
import { CategorySelect } from "./CategorySelect";

export const anyTrue = (object) => {
  if (!object) return false;
  return Object.values(object).filter((e) => e).length > 0;
};

export const getImage = (image: string | undefined, file: File | null) => {
  if (file) {
    return URL.createObjectURL(file);
  }
  if (image) {
    return image;
  }
};

export const ItemTypes = {
  CARD: "card",
};

export function AppFilterForm({
  open,
  onClose,
  appFilter,
  refetch,
  selectedTab,
}: {
  open: boolean;
  onClose: (refetch?: boolean) => void;
  refetch: (
    refetchId?: string,
    isDelete?: boolean,
    refetchAll?: boolean
  ) => void;
  appFilter?: any;
  selectedTab: string;
}) {
  const [saving, setSaving] = useState(false);
  const [originalActivity, setOriginalActivity] = useState<any>({
    filter_name: "",
  });
  const [appFilterForm, setAppFilterForm] = useState<any>({
    filter_name: "",
  });

  const handleSubmit = async (appFilterForm: any) => {
    if (!appFilterForm.filter_name) {
      return;
    }
    let payload: any = {
      filter_name: appFilterForm.filter_name,
    };
    if (selectedTab === "user_preferences") {
      payload = {
        ...payload,
        type: "user_preferences",
        user_preferences: appFilterForm.categories,
      };
    } else if (selectedTab === "explore_page_filters") {
      payload = {
        ...payload,
        type: "invite_preferences",
        explore_page_filters: appFilterForm.categories,
      };
    }
    if (appFilterForm.id) {
      payload.id = `${appFilterForm.id}`;
    }
    try {
      setSaving(true);
      const API = appFilterForm.id ? api.updateFilter : api.createFilter;
      const response = await API(payload);
      setSaving(false);
      if ([200, 201].includes(response.status) && response?.data?.status) {
        refetch("", false, true);
        if (!appFilterForm.id && response?.data?.category_id) {
          // set id
          setAppFilterForm((prev) => ({
            ...prev,
            id: response?.data?.category_id,
          }));
        }
        onClose(true);
        ShowToast({
          type: "success",
          message: response?.data?.message,
        });
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      setSaving(false);
      ShowToast({
        type: "error",
        message: "Failed to save",
      });
    }
  };

  useEffect(() => {
    if (open) {
      const categories =
        selectedTab === "user_preferences"
          ? appFilter?.user_preferences
          : appFilter?.explore_page_filters;
      setAppFilterForm({ ...appFilter, categories });
      setOriginalActivity({ ...appFilter, categories });
    }
  }, [open, appFilter, selectedTab]);

  const isObjectChanged = useMemo(() => {
    return JSON.stringify(appFilterForm) !== JSON.stringify(originalActivity);
  }, [appFilterForm, originalActivity]);

  if (!open) return null;

  const isValidate = (form = appFilterForm) => {
    return form.filter_name;
  };

  const formatAppArea = (selectedTab: string) => {
    return selectedTab
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Modal
      title={!appFilterForm?.id ? "Add Filter" : "Edit Filter"}
      open={open}
      onClose={saving ? () => { } : onClose}
      showCloseButton={true}
      contentClassName="[&_h1]:text-xl [&_.close]:w-5 [&_.close]:h-5 !max-w-[484px] [&>.header]:mb-2"
    >
      <div className="flex flex-col gap-4 pt-5 [&_.label]:!font-normal">
        <div className="flex-1 w-full flex flex-col gap-1.5">
          <div className="opacity-80 text-black text-sm font-normal leading-[17.92px]">
            Add To
          </div>
          <TextInput
            type="text"
            disabled={true}
            placeholder={formatAppArea(
              selectedTab === "user_preferences"
                ? "user_preferences"
                : "create_invites"
            )}
            className="w-full !py-[11px]"
          />
        </div>
        <div className="flex-1 w-full flex flex-col gap-1.5">
          <div className="opacity-80 text-black text-sm font-normal leading-[17.92px]">
            Filter Name
          </div>
          <TextInput
            type="text"
            placeholder="Enter filter name"
            value={appFilterForm.filter_name}
            className="w-full !py-[11px]"
            onChange={(e) =>
              setAppFilterForm((form) => ({
                ...form,
                filter_name: e.target.value,
              }))
            }
          />
        </div>
        {/* <CategorySelect
          label="User Preferences"
          value={appFilterForm.user_preferences || []}
          onChange={(value) =>
            setAppFilterForm((form) => ({
              ...form,
              user_preferences: value?.map((v) => ({
                value: v.label,
                id: v.value,
              })),
            }))
          }
        /> */}
        <CategorySelect
          label="Associated Hashtags"
          value={appFilterForm.categories || []}
          onChange={(value) => {
            setAppFilterForm((form) => ({
              ...form,
              categories: value?.map((v) => ({
                value: v.label,
                id: v.value,
              })),
            }));
          }}
        />

        <div className="flex justify-end w-full">
          <Button
            variant="primary"
            className="!rounded-full !py-2.5 w-[130px] !font-bold !text-base tracking-[-0.24px] text-white"
            onClick={() => handleSubmit(appFilterForm)}
            disabled={saving || !isValidate() || !isObjectChanged}
          >
            {!appFilterForm?.id ? "Add" : "Save"}
            {saving && <Loading />}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
