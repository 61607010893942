import { Tooltip } from "react-tooltip";
import { ReactComponent as InfoCircle } from "../../../assets/icons/info.svg";

const statusColorCode = {
  pending: {
    bg: "bg-[#EFEFEF]",
    fg: "text-[#7A7A7A]",
    text: "Pending",
  },
  done: {
    bg: "bg-[#C7F1C6]",
    fg: "text-[#078E0C]",
    icon: "[&_path]:stroke-[#078E0C]",
    text: "Done",
  },
  failed: {
    bg: "bg-[#FDE5E5]",
    fg: "text-[#DD1010]",
    icon: "[&_path]:stroke-[#DD1010]",
    text: "Failed",
  },
  processing: {
    fg: "text-[#FD9800]",
    bg: "bg-[#FFF6D7]",
    text: "Processing",
  },
  cancelled: {
    fg: "text-[#FF0000]",
    bg: "bg-[#FFD6D6]",
    text: "Cancelled",
  },
};
export function StatusTooltip({ value, importedDate, id }) {
  return (
    <div
      className={`w-fit px-2.5 py-2 ${statusColorCode[value].bg} rounded-md justify-center items-center gap-1 flex`}
    >
      <div className={`${statusColorCode[value].fg} text-sm font-normal`}>
        {statusColorCode[value].text}
      </div>
      {!!statusColorCode[value]?.icon && (
        <>
          <InfoCircle
            className={`w-4 h-4 ${statusColorCode[value].icon}`}
            data-tooltip-id={`my-tooltip-${id}`}
          />

          <Tooltip
            id={`my-tooltip-${id}`}
            className="!w-[200px] z-[2] !border-0 !text-black !rounded-lg !p-[14px] !bg-white !shadow-[0px_0px_41px_0px_rgba(0,0,0,0.18)]"
            noArrow
          >
            <div className="flex text-[13px] text-[#202020] flex-col gap-1">
              <div>{value === "failed" ? "Failed" : "Imported"} on</div>
              <div>{importedDate}</div>
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
}
