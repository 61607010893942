import { Button } from "../../../../components/Button";
import { Loading } from "../../../../components/Loading/Loading";
import { Modal } from "../../../../components/Modals/Modal";

export function PlaceDeleteConfirm({
  open,
  onClose,
  onConfirm,
  loading,
  title,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
  title: string;
}) {
  return (
    <Modal
      title={title}
      open={open}
      onClose={loading ? () => {} : onClose}
      showCloseButton={false}
      contentClassName="[&_h1]:text-xl [&_.close]:w-5 [&_.close]:h-5 !max-w-[375px] [&>.header]:mb-2"
    >
      <p className="text-[#33363F] text-base">
        All the data of this place will be marked as deleted
      </p>
      <div className="flex gap-3 mt-6">
        <Button
          variant="outline"
          className="!text-base !font-bold !rounded-full [&_.outline-variant]:!rounded-full flex-1"
          onClick={onClose}
          disabled={loading}
        >
          Go Back
        </Button>

        <Button
          onClick={onConfirm}
          disabled={loading}
          className="!text-base !font-bold !rounded-full [&_.outline-variant]:!rounded-full flex-1"
        >
          Yes
          {loading && <Loading />}
        </Button>
      </div>
    </Modal>
  );
}
