import { SideModal } from "../../../../components/Modals/SideModal";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "../../../../assets/icons/minus.svg";

import { useEffect, useMemo, useState } from "react";
import { TextInput } from "../../../../components/TextInput";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";
import { Button } from "../../../../components/Button";

function Accordion({ setFormFilters, value, label, type = "number", field }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // if already have value, set isOpen to true
    if (value[`${field}_min`] || value[`${field}_max`]) {
      setIsOpen(true);
    }
  }, [value, field]);

  return (
    <div className="p-3.5 bg-white rounded-lg border border-[#d9d9e6] flex-col justify-start items-start gap-3 inline-flex">
      <button
        type="button"
        className="self-stretch justify-between items-center flex"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="opacity-80 text-black text-sm font-normal  leading-[17.92px]">
          {label}
        </div>
        {isOpen ? (
          <MinusIcon className="w-5 h-5" />
        ) : (
          <PlusIcon className="w-5 h-5 [&_path]:stroke-black" />
        )}
      </button>
      {isOpen && (
        <>
          {type === "number" && (
            <div className="p-[13px] gap-[10px] w-full bg-white rounded-lg border border-[#d9d9e6] justify-between items-center flex">
              {/* <>
                <div className="flex items-center gap-3">
                  <label className="flex items-center gap-2 cursor-pointer">
                    <input
                      type="radio"
                      name={valueType}
                      value={value[valueType]}
                      className="h-[22px] w-[22px] cursor-pointer accent-[#0EDB9E]"
                      onClick={() => {
                        const isChecked = value[valueType] === "min";
                        setFormFilters((prev) => ({
                          ...prev,
                          [valueType]: isChecked ? null : "min",
                        }));
                      }}
                      checked={value[valueType] === "min"}
                    />
                    <span>Min</span>
                  </label>
                  <label className="flex items-center gap-2 cursor-pointer">
                    <input
                      type="radio"
                      name={valueType}
                      value={value[valueType]}
                      className="h-[22px] w-[22px] cursor-pointer accent-[#0EDB9E]"
                      onClick={() => {
                        const isChecked = value[valueType] === "max";
                        setFormFilters((prev) => ({
                          ...prev,
                          [valueType]: isChecked ? null : "max",
                        }));
                      }}
                      checked={value[valueType] === "max"}
                    />
                    <span>Max</span>
                  </label>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <TextInput
                    type={type}
                    placeholder="value"
                    onChange={(e) =>
                      setFormFilters((prev) => ({
                        ...prev,
                        [field]: e.target.value,
                      }))
                    }
                    value={value[`${field}`]}
                    className="!py-2 !w-[150px]"
                  />
                </div>
              </> */}
              <>
                <div className="flex flex-col gap-[10px]">
                  <label className="text-[#1d1e1b] text-sm font-normal">
                    Min
                  </label>
                  <TextInput
                    type={type}
                    placeholder="e.g. 10"
                    onChange={(e) =>
                      setFormFilters((prev) => ({
                        ...prev,
                        [`${field}_min`]: e.target.value,
                      }))
                    }
                    value={value[`${field}_min`] || ""}
                    className="!py-3"
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label className="text-[#1d1e1b] text-sm font-normal">
                    Max
                  </label>
                  <TextInput
                    type={type}
                    placeholder="e.g. 100"
                    onChange={(e) =>
                      setFormFilters((prev) => ({
                        ...prev,
                        [`${field}_max`]: e.target.value,
                      }))
                    }
                    value={value[`${field}_max`] || ""}
                    className="!py-3"
                  />
                </div>
              </>
            </div>
          )}
          {type === "date" && (
            <div className="flex gap-2.5 items-center w-full">
              <div className="flex flex-col gap-[10px] w-full flex-1">
                <DatePicker
                  onChange={(e) =>
                    setFormFilters((prev) => ({
                      ...prev,
                      [`${field}_start`]: e,
                    }))
                  }
                  value={value[`${field}_start`]}
                  className="!py-2"
                />
              </div>
              <div className="flex flex-col gap-[10px] w-full flex-1">
                <DatePicker
                  onChange={(e) =>
                    setFormFilters((prev) => ({
                      ...prev,
                      [`${field}_end`]: e,
                    }))
                  }
                  value={value[`${field}_end`]}
                  className="!py-2"
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export function Filters({
  isOpen,
  onClose,
  filter,
  onChange,
}: {
  isOpen: boolean;
  onClose: () => void;
  filter: {
    received_count_min: string;
    received_count_max: string;
    date_joined_start: string;
    date_joined_end: string;
    skipped_count_min: string;
    skipped_count_max: string;
    accepted_count_min: string;
    accepted_count_max: string;
    two_accepted_count_min: string;
    two_accepted_count_max: string;
    friends_count_min: string;
    friends_count_max: string;
    friend_of_friend_count_min: string;
    friend_of_friend_count_max: string;
    user_chat_count_min: string;
    user_chat_count_max: string;
  };
  onChange: (filters: any) => void;
}) {
  const [formFilters, setFormFilters] = useState<any>(filter);

  useEffect(() => {
    setFormFilters(filter);
  }, [filter]);

  const disabled = useMemo(() => {
    let flag = false;
    [
      "received_count_min",
      "received_count_max",
      "skipped_count_min",
      "skipped_count_max",
      "accepted_count_min",
      "accepted_count_max",
      "two_accepted_count_min",
      "two_accepted_count_max",
      "friends_count_min",
      "friends_count_max",
      "friend_of_friend_count_min",
      "friend_of_friend_count_max",
      "user_chat_count_min",
      "user_chat_count_max",
    ].forEach((field) => {
      if (formFilters[field]) {
        // compare with original filter
        if (formFilters[field] !== filter[field]) {
          flag = true;
        }
      }
    });

    ["date_joined_start", "date_joined_end"].forEach((key) => {
      if (filter[key] !== formFilters[key]) {
        flag = true;
      }
    });
    return flag;
  }, [formFilters, filter]);

  const onClear = () => {
    setFormFilters({
      received_count_min: "",
      received_count_max: "",
      date_joined_start: "",
      date_joined_end: "",
      skipped_count_min: "",
      skipped_count_max: "",
      accepted_count_min: "",
      accepted_count_max: "",
      two_accepted_count_min: "",
      two_accepted_count_max: "",
      friends_count_min: "",
      friends_count_max: "",
      friend_of_friend_count_min: "",
      friend_of_friend_count_max: "",
      user_chat_count_min: "",
      user_chat_count_max: "",
    });
  };

  const onApply = () => {
    onClose();
    onChange({ filter: { ...formFilters } });
  };

  const handleClose = () => {
    onClose();
    setFormFilters(filter);
  };

  return (
    <SideModal onClose={handleClose}>
      <div className="h-full w-full lg:w-[450px] relative bg-white flex flex-col">
        <div className="w-full px-5 py-[18px] border-b border-neutral-200 justify-between items-center flex">
          <div className="text-neutral-800 text-xl font-bold  leading-snug">
            Filters
          </div>
          <button type="button" onClick={handleClose}>
            <CloseIcon className="w-6 h-6 relative" />
          </button>
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div>
            <div className="p-4 flex flex-col gap-[14px] max-h-[calc(100vh-150px)] overflow-y-auto">
              <Accordion
                label="Date joined"
                setFormFilters={setFormFilters}
                value={formFilters}
                type="date"
                field="date_joined"
              />
              <Accordion
                label="Invites received"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="received_count"
                type="number"
              />
              <Accordion
                label="Invites skipped"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="skipped_count"
                type="number"
              />
              <Accordion
                label="Invites accepted"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="accepted_count"
                type="number"
              />
              <Accordion
                label="Invites with 2 users accepted"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="two_accepted_count"
                type="number"
              />
              <Accordion
                label="Number of friends"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="friends_count"
                type="number"
              />
              <Accordion
                label="Number of FoF"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="friend_of_friend_count"
                type="number"
              />
              <Accordion
                label="Number of chat messages sent"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="user_chat_count"
                type="number"
              />
            </div>
          </div>

          <div className="px-5 py-3 border-t border-neutral-200 flex justify-end">
            <Button
              variant="link"
              className="!text-[#00C88C] hover:text-[#00C88C]/80 !text-base !font-medium"
              onClick={onClear}
            >
              Clear
            </Button>

            <Button
              disabled={!disabled}
              className="!px-10 !py-3 !rounded-full"
              onClick={onApply}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </SideModal>
  );
}
