import { useSearchParams } from "react-router-dom";
import UIPopover from "../../components/Popover/Popover";
import moment from "moment";
import { Button } from "../../components/Button";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ShowToast } from "../../components/Toast";
import { api } from "../../services/api";
import { LoadAnimatedContainer } from "../../components/AnimatedContainer";
import { EmptyTableView } from "../../components/EmptyTableView";
import { useQuery } from "@tanstack/react-query";
import { AppFilterForm } from "./components/AppFIlterForm";
import { ActivityDeleteConfirm } from "./components/ActivityDeleteConfirm";
import { FilterTypeTabs } from "./components/FilterTypeTabs";
import { CategoryChipSelector } from "./components/CategoryChipSelector";

function LoadingSkeleton({ tab }: { tab: string }) {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b"
        >
          <td className="pl-5 name">
            <div className="flex items-center gap-3">
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 transition bg-gray-200 animate-pulse duration-50" />
                </div>
              </div>
            </div>
          </td>
          {tab === "user_preferences" && (
            <td>
              <div className="w-8 h-4 transition bg-gray-200 animate-pulse duration-50" />
            </td>
          )}
          <td className="subscription">
            <div className="h-4 transition bg-gray-200 w-36 animate-pulse duration-50" />
          </td>
          <td>
            <div className="w-6 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

const filterData = (data, filters) => {
  if (filters.sort_by === "preference_count") {
    if (filters.sort_order === "ASC") {
      return data.sort((a, b) => b.preference_count - a.preference_count);
    } else {
      return data.sort((a, b) => a.preference_count - b.preference_count);
    }
  }
  return data;
};

export function AppFilters() {
  const [saving, setSaving] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>("user_preferences");
  const [filters, setFilters] = useState({
    sort_by: "",
    sort_order: "",
  });
  // const {
  //   updateFilters,
  //   filters,
  //   isLoading,
  //   isFetching,
  //   refetch,
  //   activities,
  //   total,
  //   loadMore,
  // } = useFilters({ type: "all" });
  const {
    isLoading,
    data: appFilters,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["appFiltersList", selectedTab],
    queryFn: () => {
      return api.getFilters({ type: selectedTab });
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  });
  console.log("appFilters111", appFilters);
  const activities = appFilters?.data;
  const { isLoading: isCategoryLoading, data: categoryData } = useQuery({
    queryKey: ["categories"],
    queryFn: () => api.getPlaceCategories({}),
  });
  console.log("categoryData", categoryData);
  const [removePlaceModal, setRemovePlaceModal] = useState<any>(null);
  const [deleting, setDeleting] = useState(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [editActivity, setEditActivity] = useState<any>(null);

  // const handleSort = (key) => {
  //   if (key === filters.sort_by) {
  //     updateFilters({
  //       sort_by: filters.sort_order === "DESC" ? "" : key,
  //       sort_order:
  //         filters.sort_order === "ASC"
  //           ? "DESC"
  //           : filters.sort_order === "DESC"
  //           ? ""
  //           : "ASC",
  //     });
  //   } else {
  //     updateFilters({ sort_order: "ASC", sort_by: key });
  //   }
  // };

  const handleSave = async (appFilter, data, type) => {
    try {
      console.log("appFilter", appFilter);

      let tabType = "user_preferences";
      if (selectedTab === "explore_page_filters") {
        tabType = "invite_preferences";
      }
      const payload = {
        explore_page_filters: appFilter.explore_page_filters,
        user_preferences: appFilter.user_preferences,
        filter_name: appFilter.filter_name,
        [type]: data,
        type: tabType,
        id: `${appFilter.id}`,
      };

      setSaving(true);
      const response = await api.updateFilter(payload);
      setSaving(false);
      if (response.status === 200) {
        refetch();
      }

      console.log("payload", payload);
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
      console.log("error", e);
    }
  };

  const handleRemoveActivityConfirm = async () => {
    try {
      // api call
      const activity = removePlaceModal;
      setDeleting(true);

      let type = "user_preferences";
      if (selectedTab === "explore_page_filters") {
        type = "invite_preferences";
      }
      const response = await api.deleteFilter(activity.id, type);
      setDeleting(false);
      if (response.status === 200) {
        if (response.data.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch();
          setRemovePlaceModal(null);
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (e) {
      setDeleting(false);
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const observerTarget = useRef(null);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       if (
  //         entries[0].isIntersecting &&
  //         activities &&
  //         total &&
  //         activities.length < total &&
  //         !isLoading
  //       ) {
  //         loadMore();
  //       }
  //     },
  //     { threshold: 1 }
  //   );

  //   if (observerTarget.current) {
  //     observer.observe(observerTarget.current);
  //   }

  //   return () => {
  //     if (observerTarget.current) {
  //       observer.unobserve(observerTarget.current);
  //     }
  //   };
  // }, [observerTarget, activities, total, isLoading]);

  // useEffect(() => {
  //   if (user) {
  //     const newUser = places?.find(
  //       (category) => category.user_id === user.user_id
  //     );
  //     if (newUser) {
  //       setUser(newUser);
  //     }
  //   }
  // }, [places, user]);

  const handleSort = (key) => {
    if (key === filters.sort_by) {
      setFilters({
        sort_by: filters.sort_order === "DESC" ? "" : key,
        sort_order:
          filters.sort_order === "ASC"
            ? "DESC"
            : filters.sort_order === "DESC"
            ? ""
            : "ASC",
      });
    } else {
      setFilters({ sort_order: "ASC", sort_by: key });
    }
  };

  return (
    <div className="p-7 pt-6 bg-background h-[100vh]">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-black text-[32px] font-bold">App Filters</h1>
        <Button
          onClick={() => setShowAddActivityModal(true)}
          // onClick={() => handleMaintenance()}
          className="!rounded-full"
        >
          + Add a Filter
        </Button>
      </div>
      <LoadAnimatedContainer>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-5 h-[calc(100vh-115px)]">
          <div className="flex flex-wrap items-center justify-between gap-6 pl-2">
            <FilterTypeTabs
              selectedTab={selectedTab}
              onChange={(val) => {
                setSelectedTab(val);
              }}
            />
          </div>
          <div className="mt-5 text-[14px] max-h-[calc(100vh-210px)] overflow-auto">
            <table className="w-full">
              <colgroup>
                <col style={{ width: "20%" }} />
                {selectedTab === "user_preferences" && (
                  <col style={{ width: "10%" }} />
                )}
                <col
                  style={{
                    width: selectedTab === "user_preferences" ? "60%" : "70%",
                  }}
                />
                <col style={{ width: "10%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td
                    // onClick={() => handleSort("activity")}
                    className="pl-5 rounded-l"
                  >
                    <div className="flex items-center cursor-pointer">
                      Filter Name
                      {/* {filters.sort_by === "activity" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null} */}
                    </div>
                  </td>
                  {selectedTab === "user_preferences" && (
                    <td onClick={() => handleSort("preference_count")}>
                      <div className="flex items-center cursor-pointer">
                        Selected
                        {filters.sort_by === "preference_count" ? (
                          <ArrowRight
                            stroke="#2A2B2F"
                            className={`sort-icon ${
                              filters.sort_order === "ASC"
                                ? "rotate-[180deg]"
                                : "rotate-[0deg]"
                            }`}
                          />
                        ) : null}
                      </div>
                    </td>
                  )}
                  <td>
                    <div className="flex items-center cursor-pointer">
                      Associated Hashtags
                      {/* {filters.sort_by === "category" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null} */}
                    </div>
                  </td>

                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {!isLoading && !isFetching && !activities?.length && (
                  <EmptyTableView
                    cols={7}
                    message="No activities found"
                    className="h-[calc(100vh-225px)]"
                  />
                )}
                {filterData([...(activities || [])], filters)?.map(
                  (aclient, i) => (
                    <tr
                      key={aclient.id}
                      className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    >
                      <td
                        className="pl-5 name"
                        onClick={() => {
                          setShowAddActivityModal(true);
                          setEditActivity(aclient);
                        }}
                      >
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col">
                            <div className="flex items-center gap-1">
                              <h5 className="text-[#202020] text-[13px] max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis">
                                {aclient.filter_name}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </td>
                      {selectedTab === "user_preferences" && (
                        <td>{aclient.preference_count}</td>
                      )}
                      <td className="subscription">
                        <CategoryChipSelector
                          data={
                            selectedTab === "user_preferences"
                              ? aclient?.user_preferences || []
                              : aclient?.explore_page_filters || []
                          }
                          onUpdate={(data) =>
                            handleSave(aclient, data, selectedTab)
                          }
                        />
                      </td>
                      <td onClick={stopPropagate}>
                        <button
                          onClick={() => {
                            setRemovePlaceModal(aclient);
                          }}
                        >
                          <Trash />
                        </button>
                      </td>
                    </tr>
                  )
                )}
                {isLoading && <LoadingSkeleton tab={selectedTab} />}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
      </LoadAnimatedContainer>
      {showAddActivityModal && (
        <AppFilterForm
          open={showAddActivityModal}
          onClose={() => {
            setShowAddActivityModal(false);
            setEditActivity(null);
          }}
          appFilter={editActivity}
          refetch={() => refetch()}
          selectedTab={selectedTab}
        />
      )}
      {!!removePlaceModal && (
        <ActivityDeleteConfirm
          title={`Are you sure you want to remove ‘${removePlaceModal?.filter_name}’?`}
          open={!!removePlaceModal}
          onClose={() => setRemovePlaceModal(null)}
          onConfirm={handleRemoveActivityConfirm}
          loading={deleting}
        />
      )}
      {/* {showAddPlaceModal && (
        <AddPlaceForm
          open={showAddPlaceModal}
          onClose={(refresh?: boolean) => {
            if (refresh) {
              refetch();
            }
            setShowAddPlaceModal(false);
          }}
        />
      )} */}
      {/* {showAddPlaceModal && (
        <AddSearchPlace
          open={showAddPlaceModal}
          onClose={(refresh?: boolean) => {
            if (refresh) {
              refetch();
            }
            setShowAddPlaceModal(false);
          }}
        />
      )} */}
    </div>
  );
}

const Dropdown = ({ removeUser, onEdit }) => {
  return (
    <div className="z-10 w-[205px] bg-white shadow-[0px_4px_6px_0px_rgba(0,0,0,0.09)] border border-[#C8C8C8] rounded-[6px] mr-28">
      <ul className="p-1.5 text-sm " aria-labelledby="dropdownMenuIconButton">
        <li>
          <Button
            variant="icon"
            className="block !py-2 !text-[15px] !leading-[18px] font-normal text-black !px-2.5 !pr-3 hover:bg-[#F2F2F2] w-full text-left !justify-start"
            onClick={onEdit}
            scaleAnimated={false}
          >
            Edit Activity
          </Button>
        </li>
        <li>
          <Button
            variant="icon"
            className="block !py-2 !text-[15px] !leading-[18px] font-normal !px-2.5 !pr-3 hover:bg-gray-100 !text-[#C00] w-full text-left !justify-start"
            onClick={removeUser}
            scaleAnimated={false}
          >
            Delete Activity
          </Button>
        </li>
      </ul>
    </div>
  );
};
