import moment from "moment";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { SelectableChip } from "./SelectableChip";
import { useState } from "react";

export const daysOfWeekList = [
  { value: "sunday", label: "Sun" },
  { value: "monday", label: "Mon" },
  { value: "tuesday", label: "Tue" },
  { value: "wednesday", label: "Wed" },
  { value: "thursday", label: "Thu" },
  { value: "friday", label: "Fri" },
  { value: "saturday", label: "Sat" },
];

export function DaysOfWeek({ onChange, value, limitedTime, showTitle = true }) {
  const [error, setError] = useState<string>("");

  return (
    <div>
      {showTitle && (
        <div className="mb-2 text-sm font-semibold text-black">
          Days of the Week
        </div>
      )}
      <div className="mt-[15px] flex flex-wrap gap-2 items-center">
        {daysOfWeekList.map((day) => (
          <SelectableChip
            label={day.label}
            selected={!!value?.[day.value]}
            onClick={() =>
              onChange("days_of_week", {
                ...value,
                [day.value]: !value?.[day.value],
              })
            }
          />
        ))}
      </div>
      {/* <div className="flex items-center mt-3">
        <div className="flex items-center gap-2 mr-3">
          <input
            type="checkbox"
            id="place"
            name="place"
            className="w-5 h-5 border border-[#8D8E92] accent-black rounded-md"
            checked={limitedTime?.checked}
            onChange={() =>
              onChange("limited_time", {
                ...limitedTime,
                checked: !limitedTime.checked,
                startDate: "",
                endDate: "",
              })
            }
          />
          <span className="text-[#1D1E1B] text-sm">Limited time</span>
        </div>
        <div className="relative">
          <div className="flex items-center gap-1.5">
            <DatePicker
              value={limitedTime?.startDate}
              onChange={(date) => {
                if (
                  limitedTime?.endDate &&
                  moment(date).isAfter(limitedTime?.endDate)
                ) {
                  setError("Start date cannot be after the end date");
                } else {
                  setError("");
                }
                onChange("limited_time", {
                  ...limitedTime,
                  startDate: date,
                  endDate: moment(date).isAfter(limitedTime?.endDate)
                    ? ""
                    : limitedTime?.endDate,
                });
              }}
              showIcon={false}
              disabled={!limitedTime?.checked}
              minDate={new Date()}
              className="!py-2 !px-2.5 !rounded border !border-[#ddd] font-medium text-sm"
            />
            <span className="text-[#1D1E1B]/30 text-sm">to</span>
            <DatePicker
              value={limitedTime?.endDate}
              onChange={(date) => {
                if (moment(date).isBefore(limitedTime?.startDate)) {
                  setError("End date cannot be before the start date");
                } else {
                  setError("");
                }
                onChange("limited_time", {
                  ...limitedTime,
                  endDate: date,
                });
              }}
              minDate={limitedTime?.startDate || new Date()}
              showIcon={false}
              disabled={!limitedTime?.checked || !limitedTime?.startDate}
              className="!py-2 !px-2.5 !rounded border !border-[#ddd] font-medium text-sm"
            />
          </div>
          {error && (
            <div className="text-red-500 text-[11px] absolute -bottom-4">
              {error}
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
}
