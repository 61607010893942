import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Ellipse } from "../../assets/icons/ellipse.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { Button } from "../Button";
import { BACKEND_URL } from "../../env";
import userIcon from "../../assets/icons/user.svg"


const getAmplitudeLink = () => {
  if (BACKEND_URL.includes("api-dev")) {
    return "https://app.amplitude.com/analytics/primal/space/uizj0aes/all";
  } else {
    return "https://app.amplitude.com/analytics/primal/space/wlaldx37/all";
  }
}



export const menuItems = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Users",
    path: "/users",
  },
  {
    title: "Hashtags",
    path: "/categories",
  },
  {
    title: "Experiences",
    path: "/experiences",
  },
  {
    title: "App Filters",
    path: "/app-filters",
  },
  {
    title: "Places",
    path: "/places",
  },
  {
    title: "Data Import",
    path: "/data-import",
  },
  {
    title: "Amplitude",
    path: getAmplitudeLink(),
    external: true,
  },
];

export function Sidebar({ user }: { user?: any }) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const isSelected = (path) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return false;
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <div className="flex max-h-[100vh] overflow-auto rounded-r-[20px] flex-col bg-black w-[300px]">
      <div className="flex items-center self-stretch justify-center py-5 pl-2 pr-3 border-b border-white/10">
        <div className="justify-center items-center flex relative h-[70px]">
          <Logo className="w-48 h-10" />
          {/* <Ellipse className="absolute -left-9 right-0 h-[70px] w-[165px]" /> */}
        </div>
      </div>
      <div className="flex-col justify-between gap-5 flex h-[calc(100vh-70px)] pt-6 pb-5">
        <div className="flex flex-col items-start justify-start w-full gap-2 px-6">
          {menuItems.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className={`w-full px-[22px] py-3.5 rounded-[16px] items-center gap-3 flex group hover:bg-[rgba(255,255,255,0.08)] ${isSelected(item.path) ? "!bg-[rgba(255,255,255,0.16)]" : ""
                }`}
              target={item?.external === true ? "_blank" : "_self"}
            >
              {!collapsed && (
                <div className="text-lg leading-tight text-white grow shrink basis-0">
                  {item.title}
                </div>
              )}
            </Link>
          ))}
        </div>
        <div className="w-[250px] flex-col justify-start items-start gap-2.5 flex rounded-[16px] bg-[rgba(255,255,255,0.16)] mx-5">
          <div className="flex items-center self-stretch justify-between gap-2 px-4 py-3">
            <div className="inline-flex items-center justify-start gap-2">
              <div className="rounded-full w-11 h-11">
                <img
                  src={userIcon}
                  alt="profile-pic"
                  className="w-full h-full rounded-full object-fit"
                />
              </div>
              <div className="flex-col gap-0.5 flex">
                <span className="text-lg font-bold text-white">
                  {user?.admin_name}
                </span>
                <span className="text-xs font-bold text-white opacity-70">
                  admin
                </span>
              </div>
            </div>
            <Button
              variant="icon"
              className="!p-0 hover:!scale-110"
              type="button"
              onClick={handleLogout}
            >
              <LogoutIcon className="w-6 h-6" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
