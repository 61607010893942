export function OpeningHours({ opening_hours }) {
  return (
    <div className="bg-[#FBFAFA] border border-[#DDD] flex flex-col items-start text-sm gap-2 p-3 rounded-[15px]">
      <span className="text-xs text-black tracking-[-0.36px] font-bold">
        Opening hours
      </span>
      <div className="w-[392px] text-[#202020]">
        {opening_hours && opening_hours.length > 0 ? (
          <div className="space-y-3">
            {opening_hours.map((item, i) => (
              <div key={i} className="flex text-xs gap-4 flex-start">
                <span className="w-[100px] text-xs text-black/60">
                  {item.split(":")[0].trim()}:
                </span>
                <span className=" text-black text-xs">
                  {item.split(":").slice(1).join(":").trim()}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <p>No data available.</p>
        )}
      </div>
    </div>
  );
}
