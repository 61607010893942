import axios from "axios";
import { api } from "./api";

export const fileUpload = async (
  file: File,
  folder: string,
  fileName?: string,
  contentType?: string
) => {
  const uploadPayload = {
    file_name: fileName || file.name,
    folder,
    content_type: contentType || file.type,
  };
  const { uploadURL = "" } = await api.getImageUpload(uploadPayload);

  const response = await axios.put(uploadURL, file, {
    headers: { "Content-Type": file.type },
  });
  return { response, url: uploadURL.split("?")[0] };
};
