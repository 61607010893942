import { useCallback, useEffect, useState } from "react";
import { MultiSelect } from "../../places/View/component/MultiSelect";
import { api } from "../../../services/api";
import { useDebounce } from "../../../services/useDebounce";

export const CategorySelect = ({
  onChange,
  value,
  label,
  showLabel = true,
}: any) => {
  const [categories, setCategories] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const fetchCategories = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.getCategories({
        page,
        limit: 1000,
        sort_order: "DESC",
        search: debouncedSearchValue,
      });
      setCategories([...response.data]);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
    }
  }, [page, debouncedSearchValue]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);
  return (
    <div className="flex flex-col gap-1.5">
      {showLabel && (
        <label className="text-sm font-semibold text-black label">
          {label || "Hashtags"}
        </label>
      )}
      <MultiSelect
        name="category"
        onInputChange={(inputValue) => setSearchValue(inputValue)}
        options={categories?.map((category) => ({
          label: category.category_name,
          value: category.id,
        }))}
        onChange={onChange}
        value={value.map((v: any) => ({ value: v.value, label: v.label }))}
        multiValue={(base: any, data: any) => {
          const category = categories.find((c) => c.id === data.data.value);
          return {
            ...base,
            // backgroundColor: `${category?.bg_color_code} !important`,
          };
        }}
        renderLabel={(data, meta) => {
          const category = categories.find((c) => c.id === data.value);
          if (meta.context === "value") {
            return (
              <span
                style={
                  {
                    // color: category?.text_color_code,
                  }
                }
              >
                {data.label}
              </span>
            );
          }
          return (
            <div
              className={`h-[23px] w-fit px-2 py-1.5 bg-neutral-100 text-black text-[13px] font-medium rounded border border-black/5 justify-start items-center gap-1 flex`}
              style={{
                backgroundColor: category?.bg_color_code,
                color: category?.text_color_code,
              }}
            >
              {data.label}
            </div>
          );
        }}
      />
    </div>
  );
};
