function formatType(type: string) {
    // Capitalize first letter
    type = type.charAt(0).toUpperCase() + type.slice(1);
  
    // Remove underscores and capitalize letters after underscores
    type = type.replace(/_./g, match => " " + match.charAt(1).toUpperCase());
  
    return type;
  }

export default formatType;