import { Button } from "../../../components/Button";
import UIPopover from "../../../components/Popover/Popover";
import { ReactComponent as DownIcon } from "../../../assets/icons/chevron-down.svg";

export function CitySelect({ value, onChange }) {
  return (
    <UIPopover
      withChevron
      trigger={(open) => (
        <div className="flex items-center">
          <span className="text-[#33363F] text-[15px] font-semibold p-2 pr-[2px]">
            {value || "Select City"}
          </span>
          <DownIcon
            className={`w-4 h-4 mt-[4px] [&_path]:!stroke-black ${
              open ? "rotate-180" : ""
            }`}
          />
        </div>
      )}
      popoverButtonClassName="!bg-transparent"
    >
      {(close) => (
        <Dropdown
          onChange={(value) => {
            close();
            onChange(value);
          }}
          value={value}
        />
      )}
    </UIPopover>
  );
}

const Dropdown = ({ onChange, value }) => {
  return (
    <div className="z-10 w-40 bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
            onClick={() => onChange("Los Angeles")}
            scaleAnimated={false}
          >
            Los Angeles
          </Button>
        </li>
      </ul>
    </div>
  );
};
