import { ReactComponent as CloseIcon } from "../assets/icons/x.svg";

export function Chip({
  text,
  onDelete,
  bg,
  fg,
  onClick,
}: {
  onDelete?: () => void;
  text: any;
  bg?: any;
  fg?: any;
  onClick?: () => void;
}) {
  return (
    <div
      className="chip h-[30px] w-fit px-[9px] py-2 text-[#494949] text-[13px] font-medium bg-[#dedede] rounded justify-center items-center gap-1 flex"
      style={{
        backgroundColor: bg,
        color: fg,
      }}
      onClick={onClick}
    >
      <div>{text}</div>
      {onDelete && (
        <button type="button" onClick={onDelete}>
          <CloseIcon className="w-3.5 h-3.5 relative" />
        </button>
      )}
    </div>
  );
}
