
const PriceLevelMap: { [key: string]: string } = {
    "PRICE_LEVEL_FREE": "$",
    "PRICE_LEVEL_INEXPENSIVE": "$",
    "PRICE_LEVEL_MODERATE": "$$",
    "PRICE_LEVEL_EXPENSIVE": "$$$",
    "PRICE_LEVEL_VERY_EXPENSIVE": "$$$$"
};


export const priceMapper = (priceLevel: string) => {
    return priceLevel ? PriceLevelMap[priceLevel] : "-";
};