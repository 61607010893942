import { readFile } from "./CropImage";
import { useImageCropContext } from "./provider/ImageCropProvider";
import Cropper from "./components/Cropper";
import { ZoomSlider } from "./components/Sliders";
import { Button } from "../Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as FriendsIcon } from "../../assets/app/icons/friends.svg";
import { ReactComponent as CalendarIcon } from "../../assets/app/icons/calendar.svg";
import { ReactComponent as UserIcon } from "../../assets/app/icons/user.svg";
import { ReactComponent as PlusIcon } from "../../assets/app/icons/plus.svg";
import { ReactComponent as PlansIcon } from "../../assets/app/icons/plans.svg";

import { Loading } from "../Loading/Loading";
import { useState } from "react";

const normalize = (size: number) => {
  const baseWidth = 393;
  const wscale = 430 / baseWidth;
  const newSize = size * wscale;
  return `${newSize}px`;
};

const ImageCropModalContent = ({ handleDone, handleClose, isLoading }) => {
  const { setImage, resetStates, getProcessedImage } =
    useImageCropContext() as any;
  const [showPreview, setShowPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState<any>(null);

  const handleFileChange = async ({ target: { files } }) => {
    const file = files && files[0];
    const imageDataUrl = await readFile(file);
    setImage(imageDataUrl);
  };

  const handleCancel = () => {
    if (previewImage) {
      setPreviewImage(null);
    } else {
      resetStates();
      handleClose();
    }
  };

  const handlePreview = async () => {
    setShowPreview(true);
    const processedImage = await getProcessedImage();
    const img = window.URL.createObjectURL(processedImage);
    setPreviewImage(img);
  };

  const bottomPadding = normalize(55);

  return (
    <div className="text-center relative">
      <div className="flex justify-between items-center mb-7">
        <h1 className="text-black text-xl font-semibold">Edit Image</h1>
        <button onClick={handleCancel} className="group">
          <CloseIcon />
        </button>
      </div>
      <div
        className={`border border-dashed border-gray-200 p-6 rounded-lg relative overflow-auto max-h-[70vh] ${
          !isLoading && !previewImage ? "pt-[450px]" : "py-12"
        }`}
      >
        {isLoading ? (
          <div className="flex justify-center h-[479px] items-center">
            <Loading />
          </div>
        ) : (
          <>
            {previewImage && (
              <>
                <div className="w-full flex justify-center">
                  <div
                    className="flex flex-col w-[430px] h-[932px] bg-[#171717]"
                    style={{
                      borderTopLeftRadius: normalize(32),
                      borderTopRightRadius: normalize(32),
                    }}
                  >
                    <div className="flex flex-col grow-[1] rounded-b-3xl  overflow-hidden">
                      <div className="relative grow-[1]">
                        <img
                          className="grow-[1] w-full h-full object-cover"
                          src={previewImage}
                          alt="preview"
                          style={{
                            borderTopLeftRadius: normalize(32),
                            borderTopRightRadius: normalize(32),
                          }}
                        />
                        <div className="absolute -bottom-10 left-0 w-full flex justify-center z-[1]">
                          <img src="./images/facepile.png" alt="preview" />
                        </div>
                      </div>
                      <div
                        className="px-4 relative overflow-hidden"
                        style={{
                          paddingBottom: `${bottomPadding}`,
                          paddingTop: normalize(44),
                        }}
                      >
                        <div
                          className="absolute top-0 bottom-0 left-0 w-full"
                          style={{
                            backgroundImage: `url(${previewImage})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            filter: "blur(60px)",
                          }}
                        />
                        <div className="relative">
                          <div
                            className="text-center font-normal font-['SF Pro'] text-white"
                            style={{
                              lineHeight: normalize(22),
                              fontSize: normalize(17),
                              marginTop: normalize(12),
                            }}
                          >
                            Jane, Wendy, and Sam
                          </div>
                          <div
                            className="text-center text-white font-['SF Pro'] tracking-wide"
                            style={{
                              lineHeight: normalize(41),
                              fontSize: normalize(34),
                              marginTop: normalize(12),
                            }}
                          >
                            Hiking Griffith Park
                          </div>
                          <div
                            className="text-center font-normal font-['SF Pro'] text-white"
                            style={{
                              lineHeight: normalize(22),
                              fontSize: normalize(17),
                              marginTop: normalize(12),
                            }}
                          >
                            Thursday night · 2 mi away
                          </div>
                          <div
                            className="justify-center items-end gap-2 flex"
                            style={{
                              marginTop: normalize(24),
                            }}
                          >
                            <div
                              className="px-[9px] bg-black/10 rounded-[64px] justify-center items-center gap-2.5 flex"
                              style={{
                                height: normalize(30),
                              }}
                            >
                              <CalendarIcon className="w-5 h-5" />
                              <div className="text-center text-white text-[15px] font-normal font-['SF Pro'] leading-tight">
                                Calendar
                              </div>
                            </div>
                            <div
                              className="px-[9px] py-[5px] bg-black/10 rounded-[64px] justify-center items-center gap-2.5 flex"
                              style={{
                                height: normalize(30),
                              }}
                            >
                              <div className="text-center text-white text-[15px] font-normal font-['SF Pro'] leading-tight">
                                Add
                              </div>
                            </div>
                          </div>
                          <div
                            className="justify-start items-end gap-4 flex"
                            style={{
                              marginTop: normalize(24),
                            }}
                          >
                            <div
                              className="grow shrink basis-0 px-8 bg-white/90 rounded-[100px] border border-white justify-center items-center gap-2 flex"
                              style={{
                                height: `${normalize(56)}`,
                              }}
                            >
                              <div className="text-center text-white text-[22px] font-medium font-['Helvetica Neue']">
                                👍
                              </div>
                              <div className="text-center text-neutral-900 text-[17px] font-bold font-['SF Pro'] leading-snug">
                                I’m in!
                              </div>
                            </div>
                            <div
                              style={{
                                height: `${normalize(56)}`,
                              }}
                              className="grow shrink basis-0 px-8 bg-white/90 rounded-[100px] border border-white justify-center items-center gap-2 flex"
                            >
                              <div className="text-center text-white text-[22px] font-medium font-['Helvetica Neue']">
                                ⏭️
                              </div>
                              <div className="text-center text-neutral-900 text-[17px] font-bold font-['SF Pro'] leading-snug">
                                Skip
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col h-[86px] bg-[#171717] text-white">
                      <div className="mt-2 flex p-4 px-6 justify-between">
                        <div className="flex flex-col items-center">
                          <div>
                            <PlansIcon />
                          </div>
                          <div>Invited</div>
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <CalendarIcon />
                          </div>
                          <div>Plans</div>
                        </div>
                        <div className="flex flex-col items-center">
                          <PlusIcon />
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <FriendsIcon />
                          </div>
                          <div>Friends</div>
                        </div>{" "}
                        <div className="flex flex-col items-center">
                          <div>
                            <UserIcon />
                          </div>
                          <div>Profile</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!previewImage && (
              <>
                <div className="flex justify-center">
                  <div className="crop-container">
                    <Cropper />
                  </div>
                </div>
                <ZoomSlider className="mb-4" />
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className="hidden"
                  id="avatarInput"
                  accept="image/*"
                />
                <div className="flex gap-2">
                  <Button
                    variant="primary"
                    className="w-full"
                    onClick={handleDone}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    className="w-full"
                    onClick={handlePreview}
                  >
                    Preview
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ImageCropModalContent;
