import { Chip } from "../../../components/Chip";
import UIPopover from "../../../components/Popover/Popover";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-black.svg";
import { useState } from "react";
import { api } from "../../../services/api";
import { useQuery } from "@tanstack/react-query";
import { tagsToSlice } from "../utils/utils";

export function CategoryChipSelector({
  data,
  onUpdate,
}: {
  data: any[];
  onUpdate: any;
}) {
  const savedIds = data.map((item) => item.id);
  const count = tagsToSlice(data?.map((item) => item.value));
  const itemsList = data.slice(0, count);
  const remainingItemList = data.slice(count);

  const { isLoading: isCategoryLoading, data: categoryData } = useQuery({
    queryKey: ["categories"],
    queryFn: () =>
      api.getCategories({
        page: 1,
        limit: 10000,
      }),
  });

  const handleAdd = (item) => {
    const newDataSet = [
      ...itemsList,
      { id: item.id, value: item.category_name },
    ];
    onUpdate(newDataSet);
  };

  const handleDelete = (item) => {
    const newDataSet = itemsList.filter((i) => i.id !== item.id);
    onUpdate(newDataSet);
  };

  const categories = categoryData?.data || [];

  console.log("categoryData", categoryData, data, savedIds);
  return (
    <div className="flex items-center gap-2 [&_.chip]:!h-[23px] [&_.popover-open]:!bg-[#F5F5F5]">
      {itemsList.map((item) => {
        const category = categories.find((c) => c.id === item.id);

        return (
          <Chip
            key={item.id}
            text={item.value}
            bg={category?.bg_color_code}
            fg={category?.text_color_code}
            onDelete={() => handleDelete(item)}
          />
        );
      })}
      {remainingItemList.length > 0 && (
        <UIPopover
          trigger={
            <div className="h-[23px] p-[7px] text-[#707070] text-[13px] font-medium rounded border border-[#ebebeb] justify-center items-center gap-0.5 flex">
              +{remainingItemList.length}
            </div>
          }
          positions={["right", "left", "top", "bottom"]}
        >
          {(close) => (
            <Dropdown
              data={remainingItemList}
              onDelete={(item) => handleDelete(item)}
              categories={categories || []}
            />
          )}
        </UIPopover>
      )}

      <UIPopover
        trigger={
          <div className="h-[23px] w-16 p-[7px] text-[#707070] text-[13px] font-medium rounded border border-[#ebebeb] justify-center items-center gap-0.5 flex">
            <PlusIcon />
          </div>
        }
        positions={["bottom", "top"]}
      >
        {(close) => (
          <CategoryDropdown
            data={categoryData?.data || []}
            onAdd={handleAdd}
            savedIds={savedIds}
          />
        )}
      </UIPopover>
    </div>
  );
}

const CategoryDropdown = ({
  data = [],
  onAdd,
  savedIds,
}: {
  data: any[];
  onAdd?: any;
  savedIds: any[];
}) => {
  console.log("savedIds", savedIds, data);
  const filteredData = data.filter((item) => !savedIds.includes(item.id));
  return (
    <div className="z-10 [&_.chip]:!h-[23px] [&_.chip]:cursor-pointer w-fit min-w-[145px] max-h-[200px] overflow-auto mt-2.5 bg-white shadow-[0px_4px_16px_rgba(0,0,0,0.13)] rounded-[12px] p-3 flex flex-col gap-2.5">
      {filteredData?.map((item, index) => (
        <Chip
          key={index}
          text={item?.category_name}
          bg={item?.bg_color_code}
          fg={item?.text_color_code}
          onClick={() => onAdd(item)}
        />
      ))}
      {filteredData.length === 0 && (
        <div className="text-[#707070] text-[13px] font-medium text-center">
          No more categories to add
        </div>
      )}
    </div>
  );
};

const Dropdown = ({
  data = [],
  onDelete,
  categories,
}: {
  data: any[];
  onDelete?: any;
  categories: any[];
}) => {
  return (
    <div className="z-10 w-fit [&_.chip]:!h-[23px] min-w-[145px] max-h-[200px] overflow-auto ml-2.5 bg-white shadow-[0px_4px_16px_rgba(0,0,0,0.13)] rounded-[12px] p-3 flex flex-col gap-2.5">
      {data?.map((item, index) => {
        const category = categories.find((c) => c.id === item.id);
        return (
          <Chip
            key={index}
            text={item?.value}
            bg={category?.bg_color_code}
            fg={category?.text_color_code}
            onDelete={onDelete}
          />
        );
      })}
    </div>
  );
};
