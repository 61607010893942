import { FileUploader } from "react-drag-drop-files";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-2.svg";

import { useCallback, useEffect, useRef, useState } from "react";
import { Loading } from "../../../components/Loading/Loading";
import { fileUpload } from "../../../services/file-upload";
import { useDrag, useDrop } from "react-dnd";
import { ImageViewModal } from "./ImageViewModal";
import { compressImage } from "../utils/compressImage";

const fileTypes = ["JPG", "PNG", "JPEG"];

export function ImageView({
  image,
  onRemove,
  onClick,
  className,
  onSelect,
  onEdit,
}: {
  image: string;
  onRemove?: () => void;
  onClick: () => void;
  className?: string;
  onSelect?: (image: string) => void;
  onEdit?: () => void;
}) {
  return (
    <div
      className={`relative w-[82px] h-[100px] rounded-[10px] border border-dashed border-black ${className}`}
      onClick={onClick}
    >
      <img
        src={image}
        alt="activity"
        className="w-full h-full rounded-[10px]"
      />
      {onRemove && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}
          className="flex justify-center items-center absolute w-5 h-5 rounded-full bg-white top-[2px] right-[2px]"
        >
          <CloseIcon className="w-[11px] h-[11px] [&_path]:stroke-black" />
        </button>
      )}
      {onSelect && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSelect?.(image);
          }}
          className="flex justify-center items-center absolute w-5 h-5 rounded-full bg-white bottom-[2px] left-[2px]"
        >
          <CheckIcon className="w-[11px] h-[11px] [&_path]:stroke-black" />
        </button>
      )}
      {onEdit && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onEdit?.();
          }}
          className="flex justify-center items-center absolute w-5 h-5 rounded-full bg-white top-[2px] right-[2px]"
        >
          <EditIcon className="w-[11px] h-[11px] [&_path]:stroke-black" />
        </button>
      )}
    </div>
  );
}

export const ItemTypes = {
  CARD: "card",
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

function ImageCard({
  moveCard,
  url,
  index,
  id,
  handleRemoveImage,
  onDropEnd,
  onClick,
  onSelect,
}: any) {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId, isActive }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: any; isActive: boolean }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isActive:
          monitor.canDrop() &&
          monitor.isOver() &&
          !monitor.getItem().index === index,
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top;
      const hoverClientX = (clientOffset as any).x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // consider box dragging
      // dragging four directions
      // Dragging right
      // if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
      //   return;
      // }
      // // Dragging left
      // if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
      //   return;
      // }

      // // Dragging downwards
      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return;
      // }

      // // Dragging upwards
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return;
      // }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index, originalIndex: index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const { index, originalIndex } = item;
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveCard(index, originalIndex);
      } else {
        onDropEnd();
      }
    },
  });

  const opacity = isDragging ? 0 : isActive ? 0.5 : 1;
  drag(drop(ref));
  return (
    <div key={url} ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <ImageView
        image={url}
        onClick={onClick}
        onRemove={() => handleRemoveImage(url)}
        onSelect={onSelect}
      />
    </div>
  );
}

function ImageUpload({
  onUpload,
  uploading,
  type,
  image,
}: {
  onUpload: any;
  uploading: boolean;
  type: "cover" | "other";
  image?: string;
}) {
  return (
    <FileUploader
      multiple={true}
      handleChange={onUpload}
      name="file"
      types={fileTypes}
    >
      <div
        className={`${
          type === "cover"
            ? "bg-[#F8F8F8] border border-dashed border-black"
            : "bg-[#F1F1F1]"
        } flex cursor-pointer flex-col justify-center items-center rounded-[10px] w-[82px] h-[100px]`}
      >
        {uploading ? (
          <div className="relative">
            {/* <img
              src={image}
              alt="activity"
              className="w-full h-full rounded-[10px] opacity-20"
            /> */}
            <div className="w-full flex items-center justify-center [&_svg]:!mr-0">
              <Loading />
            </div>
          </div>
        ) : (
          <>
            {type === "cover" ? (
              <>
                <UploadIcon className="w-5 h-5 [&_path]:stroke-black" />
                <div className="text-xs text-black leading-[15.36px] mt-[5px]">
                  Cover
                </div>
              </>
            ) : (
              <PlusIcon className="w-6 h-6 opacity-[0.5] [&_path]:stroke-black" />
            )}
          </>
        )}
      </div>
    </FileUploader>
  );
}

export function ActivityProfilePicture({
  onChange,
  images: pictures = [],
  onSelect,
}: {
  onChange: (images: any[]) => void;
  images: any[];
  onSelect?: (image: string) => void;
}) {
  const [showImageViewModal, setShowImageViewModal] = useState<
    boolean | number
  >(false);
  const [images, setImages] = useState<string[]>(pictures);
  const [uploading, setUploading] = useState(false);
  const [coverUploading, setCoverUploading] = useState(false);
  const [coverImage, setCoverImage] = useState<string | undefined>();
  const [otherUploadingImage, setOtherUploadingImage] = useState<
    string | undefined
  >();

  const handleUpload = async (files: any, type: string) => {
    if (!files || !files.length) return;
    const setUploadState = type === "cover" ? setCoverUploading : setUploading;
    // console.log(files);
    if (type === "cover") {
      setCoverImage(URL.createObjectURL(files[0]));
    } else {
      // setOtherUploadingImage(URL.createObjectURL(file));
    }
    try {
      setUploadState(true);
      const urls: string[] = [];
      const length = files.length > 10 ? 10 : files.length;
      for (let i = 0; i < length; i++) {
        const file = files[i];
        const compressedFile = await compressImage(file);
        if (compressedFile) {
          const imageResponse = await fileUpload(compressedFile, "activities");
          if (imageResponse?.url) {
            urls.push(imageResponse.url);
          }
        }
      }
      if (urls.length) {
        onChange([...images, ...urls]);
      }
      setCoverImage(undefined);
      setOtherUploadingImage(undefined);
      setUploadState(false);
    } catch (error) {
      setUploadState(false);
      console.log(error);
    }
  };

  const handleRemove = (index: number) => {
    const newImages = images?.filter((_, i) => i !== index);
    onChange(newImages);
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setImages((images) => {
      // we need reorder files
      const prevCards = Array.from(images);
      const draggedCard = prevCards[dragIndex];
      prevCards.splice(dragIndex, 1);
      prevCards.splice(hoverIndex, 0, draggedCard);

      return prevCards;
    });
  }, []);

  useEffect(() => {
    setImages([...pictures]);
  }, [pictures]);

  return (
    <>
      {/* {!images ? (
        <FileUploader handleChange={onChange} name="file" types={fileTypes}>
          <div className="bg-[#F8F8F8] flex justify-center items-center border border-dashed border-black rounded-[10px] w-[72px] h-[72px]">
            <UploadIcon className="w-5 h-5 [&_path]:stroke-black" />
          </div>
        </FileUploader>
      ) : (
        <div className="relative w-[82px] h-[100px] rounded-[10px] border border-dashed border-black">
          <img
            src={images[0]}
            alt="activity"
            className="w-full h-full rounded-[10px]"
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className="flex justify-center items-center absolute w-5 h-5 rounded-full border-[0.769px] border-[#CACACA] bg-white top-[2px] right-[2px]"
          >
            <CloseIcon className="w-3 h-3 [&_path]:stroke-black" />
          </button>
        </div>
      )} */}
      <div className="flex items-center flex-wrap gap-2.5">
        {/* {images?.[0] ? (
          <ImageView image={images[0]} onRemove={() => handleRemove(0)} />
        ) : (
          <ImageUpload
            type="cover"
            onUpload={(e) => handleUpload(e, "cover")}
            uploading={coverUploading}
            image={coverImage}
          />
        )}
        <div className="h-[100px] border-l border-l-black/20" /> */}
        {images?.map((image, index) => (
          <ImageCard
            key={image}
            url={image}
            index={index}
            id={image}
            moveCard={moveCard}
            handleRemoveImage={() => handleRemove(index)}
            onDropEnd={() => {
              onChange(images);
            }}
            onClick={() => setShowImageViewModal(index)}
            onSelect={onSelect}
          />
        ))}
        {images.length < 10 && (
          <ImageUpload
            type="other"
            onUpload={(e) => handleUpload(e, "other")}
            uploading={uploading}
            image={otherUploadingImage}
          />
        )}
      </div>
      <ImageViewModal
        open={showImageViewModal !== false ? true : false}
        onClose={() => setShowImageViewModal(false)}
        images={images}
        index={showImageViewModal as number}
      />
    </>
  );
}
