export const stackedChartColorMap = {
  Breakfast: "#ffdbac",
  Lunch: "#f1c27d",
  Afternoon: "#e0ac69",
  // Snack: "#c2b280",
  "Before Dinner": "#e0ac69",
  Dinner: "#c68642",
  "After Dinner": "#8d5524",
  "Late Night": "#6f4f28",
  Afters: "black",
};
