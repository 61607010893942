import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDebounce } from "../../../services/useDebounce";
import { api } from "../../../services/api";
const LIMIT = 10;

function useBlockedByAdminUser() {
  const [state, setState] = useState<any>({
    page: 1,
    keyword: "",
    // status: "active",
    limit: LIMIT,
    sort_by: "name",
    sort_order: "ASC",
  });

  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const debouncedFilters = useDebounce(state, 500);

  const fetchUsers = async (
    params,
    refetchId?: string,
    isDeleted?: boolean
  ) => {
    setIsLoading(true);
    const { data, total } = await api.getBlockedUsersByAdmin({
      ...params,
    });
    setIsLoading(false);
    console.log("data", data, isDeleted, refetchId);
    // filter deleted question
    if (isDeleted && refetchId) {
      setData((prev) => prev.filter((item) => item.user_id !== refetchId));
    } else if (refetchId) {
      setData((prev) => [
        ...prev.filter((item) => item.user_id !== refetchId),
        ...data,
      ]);
    } else {
      if (params.page === 1) {
        setData([...data]);
      } else {
        setData((prev) => [...prev, ...data]);
      }
    }
    setTotal(total);
  };

  // const { data, isLoading, isFetching, refetch } = useQuery(
  //   [
  //     `blocked-by-admin-users`,
  //     debouncedFilters.page,
  //     debouncedFilters.keyword,
  //     //   debouncedFilters.status,
  //     debouncedFilters.limit,
  //   ],
  //   () => api.getBlockedUsersByAdmin(state),
  //   {
  //     enabled: !!debouncedFilters,
  //     refetchOnMount: true,
  //     refetchOnWindowFocus: false,

  //     // keepPreviousData: true,
  //   }
  // );

  // useEffect(() => {
  //   refetch();
  // }, [debouncedFilters.sort_by, debouncedFilters.sort_order, refetch]);

  const refetch = (refetchId?: string, isDeleted?: boolean) => {
    fetchUsers(debouncedFilters, refetchId, isDeleted);
  };

  const loadMore = () => {
    console.log("loadMore", data);
    // update page only we have data
    if (data?.length > 0) {
      setState((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handleFilters = (updates: any) => {
    // update filters & reset to first page
    console.log("updates", updates);
    setIsLoading(true);
    setTimeout(() => {
      setData([]);
    }, 0);
    setState((prev) => ({ ...prev, ...updates, page: 1 }));
  };

  useEffect(() => {
    console.log("debouncedFilters", debouncedFilters);
    fetchUsers(debouncedFilters);
  }, [debouncedFilters]);

  const users: any[] = data || [];
  return {
    users,
    totalPages: Math.ceil(total / LIMIT),
    isLoading: isLoading,
    isFetching,
    filters: state,
    updateFilters: (updates) => handleFilters(updates),
    refetch,
    loadMore,
    total,
    reset: () => {
      setData([]);
      setState({
        page: 1,
        keyword: "",
        // status: "active",
        limit: LIMIT,
        sort_by: "",
        sort_order: "",
      });
    },
  };
}

export default useBlockedByAdminUser;
