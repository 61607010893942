import { useEffect, useRef } from "react";
import { EmptyTableView } from "../../../../components/EmptyTableView";
import useAnalytics from "../useAnalytics";
import moment from "moment";
import Tippy from "@tippyjs/react";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/dark-info.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/Search.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { ReactComponent as BlueChevronUp } from "../../../../assets/icons/blue-chevron-up.svg";
import UIPopover from "../../../../components/Popover/Popover";
import { Button } from "../../../../components/Button";

const actionLabels = {
  all: "All Invites",
  skipped: "Skipped Invites",
  accepted: "Accepted Invites",
  became_plan: "Invites Became Plan",
};

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b"
        >
          <td className="pl-5">
            <div className="w-32 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
          <td className="account-manager">
            <div className="w-16 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
          <td className="account-executive">
            <div className="w-16 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
          <td className="mrr">
            <div className="w-20 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

const getResponse = (aclient, user_id) => {
  const response = aclient?.users?.find((user) => user.user_id === user_id);
  if (response?.status === 1) {
    return "👍 I'm in";
  } else if (response?.status === 2) {
    return "⏭️ Skipped";
  } else if (aclient?.is_plan === 1) {
    return "✅ Became Plan";
  }
  // else if (response?.status === 2) {
  //   return "✔️ Accepted";
  // }
  return "none";
};

const getEventTime = (aclient, user_id) => {
  const response = aclient?.users?.find((user) => user.user_id === user_id);
  return response?.event_time;
};

function ListItem() {
  return (
    <div className="h-10 justify-between items-center inline-flex">
      <div className="justify-start items-center gap-2.5 flex">
        <div className="w-10 h-10 rounded-full border border-black/30">
          <img
            className="w-full h-full rounded-full border border-black/30"
            src="https://via.placeholder.com/37x37"
            alt="user"
          />
        </div>
        <div className="justify-start items-center gap-2 flex">
          <div className="text-black text-base font-medium ">Wade Warren</div>
        </div>
      </div>
      <div className="justify-start items-center gap-7 flex">
        <div className="justify-start items-center gap-2 flex">
          <div className="text-center text-white text-lg font-medium font-['Helvetica Neue']">
            👍
          </div>
          <div className="text-neutral-900 text-[15px] font-bold font-['SF Pro'] leading-[18px]">
            20
          </div>
        </div>
        <div className="justify-start items-center gap-[7px] flex">
          <div className="text-center text-white text-lg font-medium font-['Helvetica Neue']">
            ⏭️
          </div>
          <div className="text-center text-neutral-900 text-[15px] font-bold font-['SF Pro'] leading-[18px]">
            4
          </div>
        </div>
      </div>
    </div>
  );
}
export function AllInvitesList({
  user_id,
  onFilterChange,
  filter,
}: {
  user_id: string;
  onFilterChange: (filter: string) => void;
  filter: string;
}) {
  const {
    users = [],
    isLoading,
    isFetching,
    total,
    loadMore,
    updateFilters,
    filters,
  } = useAnalytics({
    type: filter,
    user_id,
  });

  useEffect(() => {
    updateFilters({ type: filter, user_id });
  }, [filter, user_id]);

  const clearSearchText = () => {
    updateFilters({ keyword: "" });
  };

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          users &&
          total &&
          users.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, users, total, isLoading]);

  return (
    <div className="bg-white w-full rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-5 h-[450px]">
      <div className="flex justify-between items-center">
        <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search"
            className="outline-none"
            value={filters?.keyword}
            onChange={(e) => updateFilters({ keyword: e.target.value })}
          />
          {filters?.keyword && (
            <CloseIcon
              className={`[&_path]:stroke-black [&_path]:stroke-[1px] cursor-pointer ${
                !filters?.keyword && "opacity-0"
              }`}
              onClick={clearSearchText}
            />
          )}
        </div>
        <ActionView
          onClick={(filter) => onFilterChange(filter)}
          type={filter}
        />
      </div>
      <div className="mt-5 text-[14px] max-h-[350px] overflow-auto">
        <table className="w-full">
          <colgroup>
            <col style={{ width: "30%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>
          <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
            <tr className="sticky top-0 z-[1]">
              <td className="pl-5 rounded-l">
                <div className="flex items-center cursor-pointer">
                  Plan name
                </div>
              </td>
              <td>
                <div className="flex items-center">Plan on</div>
              </td>
              <td>
                <div className="flex items-center cursor-pointer">
                  Matched with
                  {/* {filters.sort_by === "joined_on" ? (
                  <ArrowRight
                    stroke="#2A2B2F"
                    className={`sort-icon ${
                      filters.sort_order === "ASC"
                        ? "rotate-[180deg]"
                        : "rotate-[0deg]"
                    }`}
                  />
                ) : null} */}
                </div>
              </td>
              <td>
                <div className="flex items-center cursor-pointer">Response</div>
              </td>
            </tr>
          </thead>
          <tbody>
            {!isLoading && !isFetching && !users?.length && (
              <EmptyTableView
                cols={7}
                message="No data found"
                className="h-[250px]"
              />
            )}
            {users?.map((aclient, i) => (
              <tr
                key={aclient.group_id}
                className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
              >
                <td className="pl-5 name">{aclient.activity_name}</td>
                <td className="subscription">
                  {aclient?.date_created
                    ? moment
                        .utc(getEventTime(aclient, user_id))
                        .local()
                        .format("MMM DD, YYYY | hh:mm A")
                    : "-"}
                </td>
                <td className="account-manager">
                  <div className="flex items-center gap-2">
                    <div>{aclient?.users?.length} contacts</div>
                    <Tippy
                      content={
                        <div className="flex py-4 px-[14px] bg-white rounded-[10px] shadow-[0px_4px_24px_0px_rgba(102,162,92,0.25)]">
                          <Contacts list={aclient.users} />
                        </div>
                      }
                    >
                      <InfoIcon className="mt-[3px]" />
                    </Tippy>
                  </div>
                </td>
                <td className="account-executive">
                  <span className="text-[15px] font-bold tracking-[-0.43px]">
                    {getResponse(aclient, user_id)}
                  </span>
                </td>
              </tr>
            ))}
            {(isLoading || isFetching) && <LoadingSkeleton />}
            <div ref={observerTarget}></div>
            <div className="h-5"></div>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function ActionView({ onClick, type }) {
  return (
    <UIPopover
      trigger={(isPopoverOpen) => (
        <div>
          <div className="h-[17px] justify-start items-center gap-0.5 inline-flex">
            <div className="text-black text-base font-normal">
              {actionLabels[type]}
            </div>
            <BlueChevronUp
              className={`w-4 h-4 [&_path]:stroke-black ${
                !isPopoverOpen ? "rotate-[180deg]" : ""
              }`}
            />
          </div>
        </div>
      )}
      popoverButtonClassName="!bg-transparent"
      withChevron
    >
      {(close) => (
        <div className="z-10 w-[200px] bg-white shadow-[0px_4px_10px_0px_rgba(0,0,0,0.12)] border border-[#D9D9E7] rounded-[6px] mr-32">
          <ul
            className="p-1.5 text-sm "
            aria-labelledby="dropdownMenuIconButton"
          >
            <li>
              <Button
                variant="icon"
                className="block !py-2.5 !text-[15px] !leading-[18px] font-normal text-black !px-2.5 !pr-3 hover:bg-[#F2F2F2] w-full text-left !justify-start"
                onClick={() => {
                  close();
                  onClick("all");
                }}
                scaleAnimated={false}
              >
                All
              </Button>
            </li>
            <li>
              <Button
                variant="icon"
                className="block !py-2.5 !text-[15px] !leading-[18px] font-normal text-black !px-2.5 !pr-3 hover:bg-[#F2F2F2] w-full text-left !justify-start"
                onClick={() => {
                  close();
                  onClick("skipped");
                }}
                scaleAnimated={false}
              >
                Skipped
              </Button>
            </li>
            <li>
              <Button
                variant="icon"
                className="block !py-2.5 !text-[15px] !leading-[18px] font-normal text-black !px-2.5 !pr-3 hover:bg-[#F2F2F2] w-full text-left !justify-start"
                onClick={() => {
                  close();
                  onClick("accepted");
                }}
                scaleAnimated={false}
              >
                Accepted
              </Button>
            </li>
            <li>
              <Button
                variant="icon"
                className="block !py-2.5 !text-[15px] !leading-[18px] font-normal text-black !px-2.5 !pr-3 hover:bg-[#F2F2F2] w-full text-left !justify-start"
                onClick={() => {
                  close();
                  onClick("became_plan");
                }}
                scaleAnimated={false}
              >
                Became Plan
              </Button>
            </li>
          </ul>
        </div>
      )}
    </UIPopover>
  );
}

function Contacts({ list }) {
  return (
    <div className="flex flex-col gap-[14px]">
      {list?.length > 0 && (
        <div>
          <div className="text-[#202020]/40 text-xs font-normal mb-1.5">
            Contacts ({list?.length})
          </div>
          <div className="flex flex-col gap-2">
            {list?.map((item, index) => (
              <div key={index} className="flex items-center gap-2">
                <div className="w-[17px] h-[17px] rounded-full">
                  <img
                    src={item.user_image}
                    className="w-full h-full object-cover rounded-full"
                    alt=""
                  />
                </div>
                <span className="text-sm text-[#202020]">{item.full_name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
