import { FileUploader } from "react-drag-drop-files";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add-icon.svg";
import { Loading } from "../Loading/Loading";

const fileTypes = ["JPG", "PNG", "JPEG"];

export function UploadPicture({
  onChange,
  image,
  uploading,
}: {
  onChange: (file: any) => void;
  image?: string;
  uploading?: boolean;
}) {
  return (
    <div>
      <FileUploader
        multiple={true}
        handleChange={onChange}
        name="file"
        types={fileTypes}
      >
        <div className="relative cursor-pointer flex items-center justify-center rounded-full w-[100px] h-[100px] min-w-[100px] min-h-[100px]">
          {uploading ? (
            <div className="relative">
              <img
                src={image}
                alt="activity"
                className="w-full h-full rounded-[10px] opacity-20"
              />
              <div className="absolute top-0 bottom-0 w-full flex items-center justify-center [&_svg]:!mr-0">
                <Loading />
              </div>
            </div>
          ) : (
            <>
              {image ? (
                <>
                  <img
                    src={image}
                    alt=""
                    className="w-full h-full object-cover rounded-full"
                  />
                  <div className="absolute top-0 bottom-0 left-0 right-0 rounded-full bg-[rgba(0,0,0,0.12)] flex items-center justify-center">
                    <AddIcon className="w-6 h-6" />
                    {/* <UploadIcon className="[&_path]:stroke-[#00C88C] w-6 h-6" /> */}
                  </div>
                </>
              ) : (
                <AddIcon className="w-6 h-6" />
                // <UploadIcon className="[&_path]:stroke-[#00C88C] w-6 h-6" />
              )}
            </>
          )}
        </div>
      </FileUploader>
    </div>
  );
}
