import { useEffect } from "react";
import { motion } from "framer-motion";

const framerSidebarBackground = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.1 } },
  transition: { duration: 0.3 },
};

const framerSidebarPanel = {
  initial: { x: "100%" },
  animate: { x: 0 },
  exit: { x: "100%" },
  transition: { duration: 0.3 },
};

export function SideModal({
  onClose,
  className = "",
  overlayClassName = "",
  children,
}: {
  onClose: () => void;
  className?: string;
  overlayClassName?: string;
  children: React.ReactNode;
}) {
  const handleClose = () => {
    onClose();
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "";
  };

  useEffect(() => {
    disableScroll();
    return () => enableScroll();
  }, []);

  return (
    <>
      <motion.div
        {...framerSidebarBackground}
        className={`fixed z-[1] top-0 left-0 right-0 bottom-0 bg-black/40 ${overlayClassName}`}
        onClick={handleClose}
      />
      <motion.div
        {...framerSidebarPanel}
        className={`fixed z-[2] top-0 h-[100vh] right-0 flex justify-end ${className}`}
      >
        {children}
      </motion.div>
    </>
  );
}
