import { useSearchParams } from "react-router-dom";
import UIPopover from "../../components/Popover/Popover";
import moment from "moment";
import { Button } from "../../components/Button";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as More } from "../../assets/icons/More.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ShowToast } from "../../components/Toast";
import { api } from "../../services/api";
import { LoadAnimatedContainer } from "../../components/AnimatedContainer";
import { EmptyTableView } from "../../components/EmptyTableView";
import useCategories from "./useCategories";
import formatType from "../../helpers/formatType";
import { useQuery } from "@tanstack/react-query";
import { CategoryForm } from "./components/CategoryForm";
import { frequencies } from "./components/RecommendationFrequency";
import { ActivityDeleteConfirm } from "./components/ActivityDeleteConfirm";
import { Chip } from "../../components/Chip";
import { MultipleCategoryChipView } from "../activities/components/MultipleCategoryChipView";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b"
        >
          <td className="pl-5 name">
            <div className="flex items-center gap-3">
              <div className="flex flex-col gap-1">
                <div className="w-16 h-4 transition bg-gray-200 animate-pulse duration-50" />
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="h-4 transition bg-gray-200 w-36 animate-pulse duration-50" />
          </td>
          <td className="account-manager">
            <div className="w-20 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
          <td>
            <div className="w-6 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function CategoryList() {
  const {
    updateFilters,
    filters,
    isLoading,
    isFetching,
    refetch,
    activities,
    total,
    loadMore,
  } = useCategories({ type: "all" });
  const { isLoading: isCategoryLoading, data: categoryData } = useQuery({
    queryKey: ["categories"],
    queryFn: () => api.getPlaceCategories({}),
  });
  console.log("categoryData", categoryData);
  const [removePlaceModal, setRemovePlaceModal] = useState<any>(null);
  const [place, setPlace] = useState<any>(null);
  const [deleting, setDeleting] = useState(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [editActivity, setEditActivity] = useState<any>(null);

  const handleSort = (key) => {
    if (key === filters.sort_by) {
      updateFilters({
        sort_by: filters.sort_order === "DESC" ? "" : key,
        sort_order:
          filters.sort_order === "ASC"
            ? "DESC"
            : filters.sort_order === "DESC"
              ? ""
              : "ASC",
      });
    } else {
      updateFilters({ sort_order: "ASC", sort_by: key });
    }
  };

  const handleRemoveActivityConfirm = async () => {
    try {
      // api call
      const activity = removePlaceModal;
      setDeleting(true);
      const response = await api.deleteCategory(activity.id);
      setDeleting(false);
      if (response.status === 200) {
        if (response.data.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch(activity.id, true);
          setRemovePlaceModal(null);
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (e) {
      setDeleting(false);
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          activities &&
          total &&
          activities.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, activities, total, isLoading]);

  // useEffect(() => {
  //   if (user) {
  //     const newUser = places?.find(
  //       (category) => category.user_id === user.user_id
  //     );
  //     if (newUser) {
  //       setUser(newUser);
  //     }
  //   }
  // }, [places, user]);

  const clearSearchText = () => {
    updateFilters({ search: "" });
  };

  return (
    <div className="p-7 pt-6 bg-background h-[100vh]">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-black text-[32px] font-bold">Hashtags</h1>
        <Button
          onClick={() => setShowAddActivityModal(true)}
          // onClick={() => handleMaintenance()}
          className="!rounded-full"
        >
          + Add a hashtag
        </Button>
      </div>
      <LoadAnimatedContainer>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-5 h-[calc(100vh-115px)]">
          <div className="flex flex-wrap items-center justify-between gap-6 pl-2">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  value={filters?.search}
                  onChange={(e) => updateFilters({ search: e.target.value })}
                />
                <CloseIcon
                  className={`[&_path]:stroke-black [&_path]:stroke-[1px] cursor-pointer ${!filters?.search && "opacity-0"
                    }`}
                  onClick={clearSearchText}
                />
              </div>
            </div>
            {/* <div className="flex items-center gap-3">
              <Button
                variant="link"
                scaleAnimated={false}
                className="!p-0 !gap-0 items-center text-base rounded-none hover:!text-blackButtonHover group text-black"
                onClick={() => setReportedUserModal(true)}
              >
                Reported users{" "}
                <ChevronRight className="transition duration-75 group-hover:translate-x-[2px]" />
              </Button>
              <div className="h-[13px] w-[1px] bg-[#ddd]" />
              <Button
                variant="link"
                scaleAnimated={false}
                className="!p-0 mr-5 !gap-0 items-center text-base rounded-none hover:!text-blackButtonHover group text-black"
                onClick={() => setBlockedUserModal(true)}
              >
                Blocked users{" "}
                <ChevronRight className="transition duration-75 group-hover:translate-x-[2px]" />
              </Button>
            </div> */}
          </div>
          <div className="mt-5 text-[14px] max-h-[calc(100vh-210px)] overflow-auto">
            <table className="w-full">
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "50%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td
                    onClick={() => handleSort("activity")}
                    className="pl-5 rounded-l"
                  >
                    <div className="flex items-center cursor-pointer">
                      Hashtag
                      {filters.sort_by === "activity" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${filters.sort_order === "ASC"
                            ? "rotate-[180deg]"
                            : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td onClick={() => handleSort("category")}>
                    <div className="flex items-center cursor-pointer">
                      Experiences
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center cursor-pointer">
                      Added on
                      {/* {filters.sort_by === "blog_count" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null} */}
                    </div>
                  </td>

                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {!isLoading && !isFetching && !activities?.length && (
                  <EmptyTableView
                    cols={7}
                    message="No activities found"
                    className="h-[calc(100vh-225px)]"
                  />
                )}
                {activities?.map((aclient, i) => (
                  <tr
                    key={aclient.id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                  >
                    <td
                      className="pl-5 name"
                      onClick={() => {
                        setShowAddActivityModal(true);
                        setEditActivity(aclient);
                      }}
                    >
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <div className="flex items-center gap-1">
                            <Chip
                              text={aclient?.category_name}
                              bg={aclient?.bg_color_code}
                              fg={aclient?.text_color_code}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="[&_.chip]:!text-[#707070] [&_.chip]:!border [&_.chip]:!border-[#ebebeb] [&_.chip]:!bg-transparent">
                      <MultipleCategoryChipView
                        data={aclient?.experiences || []}
                        itemsToShow={3}
                        fieldName=""
                        isOutline
                      />
                    </td>
                    <td>
                      {aclient?.date_created
                        ? moment
                          .utc(aclient.date_created)
                          .local()
                          .format("MMM DD, YYYY")
                        : "-"}
                    </td>
                    <td onClick={stopPropagate}>
                      <UIPopover
                        trigger={
                          <span className="block p-2 border rounded-lg border-gray-270">
                            <More />
                          </span>
                        }
                      >
                        {(close) => (
                          <Dropdown
                            removeUser={() => {
                              close();
                              setRemovePlaceModal(aclient);
                            }}
                            onEdit={() => {
                              close();
                              setShowAddActivityModal(true);
                              setEditActivity(aclient);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {(isLoading || isFetching) && <LoadingSkeleton />}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
      </LoadAnimatedContainer>
      {showAddActivityModal && (
        <CategoryForm
          open={showAddActivityModal}
          onClose={() => {
            setShowAddActivityModal(false);
            setEditActivity(null);
          }}
          category={editActivity}
          refetch={refetch}
        />
      )}
      {!!removePlaceModal && (
        <ActivityDeleteConfirm
          title={`Are you sure you want to remove ‘${removePlaceModal?.category_name}’?`}
          open={!!removePlaceModal}
          onClose={() => setRemovePlaceModal(null)}
          onConfirm={handleRemoveActivityConfirm}
          loading={deleting}
        />
      )}
      {/* {showAddPlaceModal && (
        <AddPlaceForm
          open={showAddPlaceModal}
          onClose={(refresh?: boolean) => {
            if (refresh) {
              refetch();
            }
            setShowAddPlaceModal(false);
          }}
        />
      )} */}
      {/* {showAddPlaceModal && (
        <AddSearchPlace
          open={showAddPlaceModal}
          onClose={(refresh?: boolean) => {
            if (refresh) {
              refetch();
            }
            setShowAddPlaceModal(false);
          }}
        />
      )} */}
    </div>
  );
}

const Dropdown = ({ removeUser, onEdit }) => {
  return (
    <div className="z-10 w-[170px] bg-white shadow-[0px_4px_6px_0px_rgba(0,0,0,0.09)] border border-[#C8C8C8] rounded-[6px] mr-28">
      <ul className="p-1.5 text-sm " aria-labelledby="dropdownMenuIconButton">
        <li>
          <Button
            variant="icon"
            className="block !py-2 !text-[15px] !leading-[18px] font-normal text-black !px-2.5 !pr-3 hover:bg-[#F2F2F2] w-full text-left !justify-start"
            onClick={onEdit}
            scaleAnimated={false}
          >
            Edit
          </Button>
        </li>
        <li>
          <Button
            variant="icon"
            className="block !py-2 !text-[15px] !leading-[18px] font-normal !px-2.5 !pr-3 hover:bg-gray-100 !text-[#C00] w-full text-left !justify-start"
            onClick={removeUser}
            scaleAnimated={false}
          >
            Remove
          </Button>
        </li>
      </ul>
    </div>
  );
};
