export function formatPhoneNumber(phoneNumber: string | undefined): string {
    if (!phoneNumber) return 'No phone number available';
  
    // Extract the area code, first three digits, and last four digits
    const areaCode = phoneNumber.slice(0, 3);
    const firstThree = phoneNumber.slice(3, 6);
    const lastFour = phoneNumber.slice(6, 10);
  
    // Combine the parts into the desired format
    return `(${areaCode}) ${firstThree}-${lastFour}`;
}