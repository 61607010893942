import { Modal } from "../../../components/Modals/Modal";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { useState } from "react";
import useSearchPlaces from "./useSearchPlaces";
import { Button } from "../../../components/Button";
import { PlaceView } from "../View/View";
import { PlaceItemView } from "./PlaceItemView";
import { SelectedPlaces } from "./SelectedPlaces";
import { Loading } from "../../../components/Loading/Loading";
import { api } from "../../../services/api";
import { ShowToast } from "../../../components/Toast";
import { CitySelect } from "./CitySelect";

export function AddSearchPlace({ onClose, open }) {
  const { filters, updateFilters, places, isLoading } = useSearchPlaces();
  const [selectedPlaces, setSelectedPlaces] = useState<any[]>([]);
  const [saving, setSaving] = useState(false);

  const clearSearchText = () => {
    updateFilters({ keyword: "" });
  };

  const handleSubmit = async () => {
    try {
      setSaving(true);
      const placeData = selectedPlaces.map((s) => ({
        place_id: s.id,
        name: s?.displayName?.text,
        address: s?.formattedAddress,
        rating: s?.rating,
        price_level: s?.priceLevel,
        primary_type: s?.primaryTypeDisplayName?.text,
      }));
      console.log(placeData);
      const response = await api.addPlaceUrls({
        data: placeData,
      });
      setSaving(false);
      if (response.status === 200) {
        if (response?.data?.status) {
          console.log(response);
          onClose(true);

          if (response?.data?.data?.failed_places?.length > 0) {
            ShowToast({
              type: "error",
              message:
                response?.data?.data?.failed_places?.length !== placeData.length
                  ? "Some selected places are already importing, but others have been added"
                  : "Some selected places are already scheduled for import",
            });
          } else {
            ShowToast({
              type: "success",
              message: "Data collection started",
            });
          }
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      console.log(e);
      setSaving(false);
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleClick = (place) => {
    const index = selectedPlaces.findIndex((e) => e.id === place.id);
    if (index === -1) {
      setSelectedPlaces([...selectedPlaces, place]);
    } else {
      setSelectedPlaces(selectedPlaces.filter((e) => e.id !== place.id));
    }
  };
  console.log("selectedPlaces", selectedPlaces);
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      showCloseButton={true}
      contentClassName="[&_h1]:text-xl [&_.close]:w-5 [&_.close]:h-5 !max-w-[484px] [&>.header]:mb-2 [&>.header]:mx-3 !px-4"
    >
      <div>
        <div className="!mx-3 header flex items-center justify-between mb-5">
          <h1 className="text-black text-[32px] font-bold">Add a place</h1>
          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <span>City:</span>
              <CitySelect
                value={filters.city}
                onChange={(value) => {
                  updateFilters({ city: value });
                }}
              />
            </div>
            <button onClick={() => onClose()} aria-label="Close">
              <CloseIcon className="w-6 h-6 close" />
            </button>
          </div>
        </div>
        <div className="!mx-3 rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
          <SearchIcon />
          <input
            type="text"
            placeholder="Restaurant/place name"
            className="outline-none text-base w-full"
            value={filters?.keyword}
            onChange={(e) => updateFilters({ keyword: e.target.value })}
          />
          <CloseIcon
            className={`[&_path]:stroke-black [&_path]:stroke-[1px] cursor-pointer ${
              !filters?.keyword && "opacity-0"
            }`}
            onClick={clearSearchText}
          />
        </div>
        {selectedPlaces.length > 0 && (
          <SelectedPlaces
            selectedPlaces={selectedPlaces}
            setSelectedPlaces={setSelectedPlaces}
          />
        )}
        <div className="h-[calc(100vh-325px)] overflow-auto px-4">
          {isLoading && (
            <div className="h-full flex justify-center items-center">
              <Loading />
            </div>
          )}
          {places.map((place) => (
            <PlaceItemView
              onClick={() => handleClick(place)}
              checked={selectedPlaces.find((e) => e.id === place.id)}
              key={place.id}
              place={place}
            />
          ))}
          {!isLoading && places.length === 0 && (
            <div className="text-center text-zinc-400 text-base h-full flex justify-center items-center">
              No place picked
            </div>
          )}
        </div>
        <Button
          className="w-full !rounded-full"
          disabled={selectedPlaces.length === 0 || saving}
          onClick={handleSubmit}
          loading={saving}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}
