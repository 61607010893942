import { useEffect, useState } from "react";
import { Modal } from "../../../components/Modals/Modal";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/Chevrons.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";

export function ImageViewModal({
  open,
  onClose,
  images,
  index,
}: {
  open: boolean;
  onClose: () => void;
  images: string[];
  index: number;
}) {
  const [selectedIndex, setSelectedIndex] = useState(index);

  const handlePrevious = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedIndex(selectedIndex - 1);
  };

  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedIndex(selectedIndex + 1);
  };

  useEffect(() => {
    setSelectedIndex(index);
  }, [index]);

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto backdrop-blur-sm ${
        open ? "block" : "hidden"
      }`}
      onClick={onClose}
    >
      <div className="flex items-center justify-center min-h-screen relative">
        <div className="fixed inset-0 bg-black bg-opacity-40" />
        <div className="absolute top-2 py-[3px] px-2 right-2 bg-black/50 rounded-[9px] text-white text-xs tracking-[-0.36px] poppins">
          {selectedIndex + 1} / {images.length}
        </div>
        <div
          className={`relative z-[1] rounded-[16px] max-w-[75vw] w-full mx-6 md:mx-auto px-7 pb-7 pt-6 flex flex-col`}
        >
          {/* <button
            onClick={onClose}
            aria-label="Close"
            className="absolute top-2 -right-6"
          >
            <CloseIcon className="w-8 h-8 close [&_path]:stroke-white" />
          </button> */}
          <div className="flex items-center justify-center relative">
            {/* {selectedIndex > 0 && ( */}
            <button
              style={{
                backdropFilter: "blur(24px)",
                WebkitBackdropFilter: "blur(24px)",
              }}
              className="bg-white p-2 rounded-full absolute left-2 disabled:opacity-50"
              onClick={handlePrevious}
              disabled={selectedIndex === 0}
            >
              <ChevronIcon className="rotate-180 w-6 h-6" />
            </button>
            {/* )} */}
            <div
              className="w-[75vw] h-[80vh] rounded-md object-contain"
              style={{ display: "contents" }}
            >
              <img
                src={images[selectedIndex]}
                alt="activity"
                className="max-w-[90vw] max-h-[90vh] rounded-md object-contain"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            {/* {images.length > selectedIndex + 1 && ( */}
            <button
              style={{
                backdropFilter: "blur(24px)",
                WebkitBackdropFilter: "blur(24px)",
              }}
              onClick={handleNext}
              className="bg-white p-2 rounded-full absolute right-2 disabled:opacity-50"
              disabled={selectedIndex + 1 === images.length}
            >
              <ChevronIcon className="w-6 h-6" />
            </button>
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
