import moment from "moment";
import { ReactComponent as AmplitudeIcon } from "../../../../assets/icons/amplitude.svg";
import { formatPhoneNumber } from "react-phone-number-input";
import { CrownTooltip } from "../../components/CrownTooltip";
import { AMPLITUDE_PROJECT_ID } from "../../../../env";

export function UserInfoCard({ user, userData }) {
  return (
    <div className="flex justify-between items-stretch bg-white rounded-[16px] p-6">
      <div className="flex items-start gap-[14px]">
        <div className="w-[92px] h-[92px] rounded-full">
          <img
            src={user.user_image}
            alt=""
            className="object-cover w-full h-full rounded-full"
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2 ">
            <span className="text-lg text-[#33363F] font-bold">
              {user.full_name}
            </span>
            {user.is_plus_user && <CrownTooltip className="w-5 h-5" />}
          </div>
          <div className="flex items-center gap-2 text-sm">
            <span className="text-[#686868]">User ID:</span>
            <span className="text-[#202020]">{user.user_id}</span>
          </div>
          {/* <div className="flex items-center gap-2 text-sm">
            <span className="text-[#686868]">Age:</span>
            <span className="text-[#202020]">{user.user_age}</span>
          </div> */}
          <div className="flex items-center gap-2 text-sm">
            <span className="text-[#686868]">Phone#:</span>
            <span className="text-[#202020]">
              {formatPhoneNumber(`${user.country_code}${user.u_phone_number}`)}
            </span>
          </div>
          <div className="grid grid-cols-2 gap-1">
            <div className="flex items-center text-sm gap-2">
              <span className="text-[#686868]">Number of Friends:</span>{" "}
              {userData?.friends_count}
            </div>
            <div className="flex items-center text-sm gap-2">
              <span className="text-[#686868]">
                Number of Friends of Friends:
              </span>{" "}
              {userData?.friend_of_friend_count}
            </div>
            <div className="flex items-center text-sm gap-2">
              <span className="text-[#686868]">Plans Made:</span>{" "}
              {userData?.two_user_accepted_count}
            </div>
            <div className="flex items-center text-sm gap-2">
              <span className="text-[#686868]">Messages Sent:</span>{" "}
              {userData?.user_chat_count}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <div className="flex flex-col items-end">
          <span className="text-[#686868]">Joined on:</span>
          <span className="text-[#202020]">
            {moment.utc(user.date_created).local().format("MMM DD,YYYY")}
          </span>
        </div>

        <a
          target="_blank"
          rel="noreferrer"
          href={`https://app.amplitude.com/analytics/primal/project/${AMPLITUDE_PROJECT_ID}/search/user_id=${user?.u_phone_number}`}
          className="flex items-center gap-1.5 text-sm font-medium hover:underline"
        >
          <AmplitudeIcon className="w-4 h-4" />
          Amplitude
        </a>
      </div>
    </div>
  );
}
