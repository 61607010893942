import ChevronDown from "../../assets/icons/chevron-down.svg";

export function SelectInput({
  data,
  ...props
}: React.InputHTMLAttributes<HTMLSelectElement> & { data?: string[] | any[] }) {
  return (
    <div className="w-full">
      <select
        {...props}
        className={`!outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg appearance-none cursor-pointer border border-lightGray focus:border-grey2 p-4 text-base ${
          props.value ? "text-black" : "text-dark3"
        } ${props.className || ""}`}
        required
        style={{
          backgroundImage: `url(${ChevronDown})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "97% 55%",
          backgroundSize: "1.5rem",
        }}
      >
        <option className="text-dark3" value={""}>
          {props.placeholder || "Select"}
        </option>
        {data
          ?.filter((item) =>
            typeof item === "object"
              ? item.label !== props.value
              : item !== props.value
          )
          .map((item, index) =>
            typeof item === "object" ? (
              <option key={index} value={item.value}>
                <div className="w-1 h-1 bg-[#EE826B]" />
                {item.label}
              </option>
            ) : (
              <option key={index} value={item} selected={item === props.value}>
                {item}
              </option>
            )
          )}
      </select>
    </div>
  );
}
