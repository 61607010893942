import { useState, useEffect, useRef } from "react";

export const useDebounce = (value, milliSeconds) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const handlerRef = useRef<any>(null);

  useEffect(() => {
    handlerRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, milliSeconds);

    return () => {
      clearTimeout(handlerRef.current);
    };
  }, [value, milliSeconds]);

  return debouncedValue;
};
