import { useEffect, useState } from "react";
import { Loading } from "../../../components/Loading/Loading";
import { CitySelect } from "../../places/PlacesForm/CitySelect";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import useSearchPlaces from "../../places/PlacesForm/useSearchPlaces";
import { PlaceItemView } from "../../places/PlacesForm/PlaceItemView";
import { AddedPlaces } from "./AddedPlaces";

function SearchPlaceList({
  isLoading,
  places,
  selectedPlaces,
  handleClick,
  onClose,
}) {
  useEffect(() => {
    // Close the dropdown when the user clicks outside
    const handleClick = (e) => {
      console.log("came here", e);
      // check if element is not in the selected places dropdown
      const element = document.querySelector(".search-places");
      if (element && !element.contains(e.target)) {
        onClose();
      }
    };
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="absolute z-[2] left-0 right-0 top-[50px] shadow-[0px_4px_9px_0px_rgba(0,0,0,0.13)] rounded-[5px] border border-[#ddd] bg-white h-[calc(100vh-325px)] overflow-auto px-4">
      {isLoading && (
        <div className="h-full flex justify-center items-center">
          <Loading />
        </div>
      )}
      {places.map((place) => (
        <PlaceItemView
          onClick={() => handleClick(place)}
          checked={selectedPlaces.find((e) => e.id === place.id)}
          key={place.id}
          place={place}
          shouldDisable={false}
          type="activity"
          prefix="search-places"
        />
      ))}
      {!places.length && !isLoading && (
        <div className="h-full flex justify-center items-center">
          <p>No place available</p>
        </div>
      )}
    </div>
  );
}

export function PlacesList({
  places,
  onChange,
  onEdit,
}: {
  places: any[];
  onChange: any;
  onEdit: any;
}) {
  const {
    filters,
    updateFilters,
    places: placeList,
    isLoading,
  } = useSearchPlaces();
  const [showSearchList, setShowSearchList] = useState(false);

  const handleClick = (place) => {
    const index = places.findIndex((e) => e.id === place.id);
    if (index === -1) {
      onChange([...places, place]);
    } else {
      onChange(places.filter((e) => e.id !== place.id));
    }
  };

  const clearSearchText = () => {
    updateFilters({ keyword: "" });
  };

  const handleRemove = (place) => {
    onChange(places.filter((e) => e.id !== place.id));
  };

  useEffect(() => {
    if (filters?.keyword) {
      setShowSearchList(true);
    }
  }, [filters?.keyword]);

  return (
    <div className="rounded-md bg-[#F3F3F3]/60 h-full py-2">
      <div className="h-full flex flex-col">
        <div className="!mx-6 header flex items-center justify-between mb-3">
          <h1 className="text-black text-base font-semibold">Add a place</h1>
          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <span>City:</span>
              <CitySelect
                value={filters.city}
                onChange={(value) => {
                  updateFilters({ city: value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="search-places !mx-6 relative rounded-lg bg-white border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
          <SearchIcon />
          <input
            type="text"
            placeholder="Restaurant/place name"
            className="outline-none text-base w-full"
            value={filters?.keyword}
            onChange={(e) => updateFilters({ keyword: e.target.value })}
            // onFocus={() => setShowSearchList(true)}
          />
          <CloseIcon
            className={`[&_path]:stroke-black [&_path]:stroke-[1px] cursor-pointer ${
              !filters?.keyword && "opacity-0"
            }`}
            onClick={clearSearchText}
          />
          {showSearchList && (
            <SearchPlaceList
              places={placeList}
              isLoading={isLoading}
              selectedPlaces={places}
              handleClick={handleClick}
              onClose={() => setShowSearchList(false)}
            />
          )}
        </div>

        <div className="text-[#7F7F7F] text-sm font-semibold mx-6 mt-6">
          Added Places {places?.length > 0 ? `(${places.length})` : ""}
        </div>
        {places.length > 0 && (
          <AddedPlaces
            selectedPlaces={places}
            onRemove={handleRemove}
            onEdit={onEdit}
          />
        )}
        {!places.length && (
          <div className="flex-1 h-full flex justify-center items-center mb-16 text-sm text-[#555555]">
            No place added
          </div>
        )}
      </div>
    </div>
  );
}
