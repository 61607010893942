import Select from "react-select";

export function MultiSelect({
  options,
  onChange,
  value,
  name,
  onInputChange,
  renderLabel,
  multiValue,
}: {
  options: { label: string; value: string }[];
  onChange: any;
  value: string[] | { label: string; value: string }[];
  name: string;
  onInputChange?: any;
  renderLabel?: any;
  multiValue?: any;
}) {
  return (
    <div>
      <Select
        isMulti
        name={name}
        options={options}
        value={value.map(
          (v) =>
            options.find((o) => o.value === v?.value) || {
              label: v?.label || v,
              value: v?.value || v,
            }
        )}
        formatOptionLabel={(data, meta) =>
          renderLabel ? renderLabel(data, meta) : data.label
        }
        classNamePrefix="select"
        isClearable={false}
        onChange={(selectedOptions) => onChange(selectedOptions)}
        onInputChange={onInputChange}
        styles={{
          multiValue,
        }}
      />
    </div>
  );
}
