import { useEffect, useRef, useState } from "react";
import { api } from "../../services/api";
import { useDebounce } from "../../services/useDebounce";
export const LIMIT = 25;

function useDataCollection() {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: LIMIT,
  });
  const [state, setState] = useState<any>({
    keyword: "",
    sort_by: "",
    sort_order: "",
    start_date: "",
    end_date: "",
    type: "all",
    categories: [],
  });
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const debouncedFilters = useDebounce(state, 500);
  const fetchingParams = useRef<any>();

  const fetchQuestions = async (
    params,
    pageInfo = pagination,
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    try {
      setIsLoading(true);
      fetchingParams.current = params;
      const { data = [], total } = await api.getPlaceHistory({
        ...params,
        ...pageInfo,
      });
      setIsLoading(false);
      // filter deleted question
      if (isDeleted && refetchId) {
        setData((prev) =>
          prev.filter((item) => item.question_id !== refetchId)
        );
      } else if (refetchId) {
        setData((prev) => [
          ...prev.filter((item) => item.question_id !== refetchId),
          ...data,
        ]);
      } else if (refetchAll) {
        const newData: any[] = [];
        // refetch all pages
        for (let i = 1; i <= pageInfo.page; i++) {
          const { data } = await api.getPlaceHistory({
            ...params,
            ...pageInfo,
            page: i,
          });
          newData.push(...data);
        }
        setData(newData);
      } else {
        if (pageInfo.page === 1) {
          setData([...data]);
        } else {
          setData((prev) => [...prev, ...data]);
        }
      }
      setTotal(total);
    } catch (e) {
      console.log(e);
    }
  };

  const refetch = (
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    fetchQuestions(
      debouncedFilters,
      pagination,
      refetchId,
      isDeleted,
      refetchAll
    );
  };

  const loadMore = () => {
    // update page only we have data
    const page = pagination.page;
    const totalPage = Math.ceil(total / LIMIT);
    if (page < totalPage) {
      setIsLoading(true);
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      fetchQuestions(debouncedFilters, {
        ...pagination,
        page: pagination.page + 1,
      });
    }
  };

  const handleFilters = (updates: any) => {
    // update filters & reset to first page
    setIsLoading(true);
    setData([]);
    setPagination((prev) => ({ ...prev, page: 1 }));
    setState((prev) => ({ ...prev, ...updates }));
  };

  useEffect(() => {
    fetchQuestions(debouncedFilters);
  }, [debouncedFilters]);

  const places: any[] = data || [];
  return {
    places,
    totalPages: Math.ceil(total / LIMIT),
    total: total,
    pagination,
    isLoading: isLoading,
    isFetching,
    filters: state,
    updateFilters: (updates) => handleFilters(updates),
    refetch,
    loadMore,
    reset: () => {
      setData([]);
      setPagination({ page: 1, limit: LIMIT });
      setState({
        keyword: "",
        sort_by: "",
        sort_order: "",
        start_date: "",
        end_date: "",
        type: "all",
        categories: [],
      });
    },
  };
}

export default useDataCollection;
