// A hook which triggers the save function every 2 seconds
// It will take updated data as an argument and compare it with the previous data
// if the data is different, it will trigger the save function
// It will also clear the timer when the component is unmounted

import { useCallback, useEffect, useRef } from "react";

export const useAutoSave = (save: (data: any) => void, data: any) => {
  const previousData = useRef(data);
  const dataRef = useRef(data);
  const timer = useRef<any>();

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const updatePreviousData = (data: any) => {
    previousData.current = data;
  };

  // Save the data every 2 seconds
  const saveData = useCallback(() => {
    if (
      previousData.current &&
      dataRef.current &&
      JSON.stringify(previousData.current) !== JSON.stringify(dataRef.current)
    ) {
      console.log(previousData.current, dataRef.current);
      save(dataRef.current);
      // Update the previous data
      updatePreviousData(dataRef.current);
    }
  }, []);

  useEffect(() => {
    timer.current = setInterval(saveData, 2000);
    return () => {
      clearInterval(timer.current);
    };
  }, [saveData]);

  return { updatePreviousData };
};
