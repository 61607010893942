import { TextInput } from "../../../../components/TextInput";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "../../../../components/Button";
import { UploadPicture } from "../../../../components/Uploads/UploadPicture";
import { api } from "../../../../services/api";
import { fileUpload } from "../../../../services/file-upload";
import { Loading } from "../../../../components/Loading/Loading";
import { ShowToast } from "../../../../components/Toast";
import { TextArea } from "../../../../components/TextArea";
import { FullModal } from "../../../../components/Modals/FullModal";
import { priceMapper } from "./../../../../helpers/priceMapper";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { SelectInput } from "../../../../components/SelectInput";
import { Prices } from "../../../../helpers/dropdown";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { DaysOfWeek } from "../../../activities/components/DaysOfWeek";
import { TimeOfDay } from "../../../activities/components/TimeOfDay";
import { dummyPlace } from "./dummy";
import { MultiSelect } from "./MultiSelect";
import { OpeningHours } from "../../../activities/components/OpeningHours";
import { useQuery } from "@tanstack/react-query";
import { useAutoSave } from "./useAutoSave";
import { anyTrue } from "../../../activities/components/ActivityForm";

export const statusList = [
  { label: "Submitted", value: 0 },
  { label: "Approved", value: 2 },
  { label: "Live", value: 3 },
  { label: "Boosted", value: 1 },
];

export const getImage = (image: string | undefined, file: File | null) => {
  if (file) {
    return URL.createObjectURL(file);
  }
  if (image) {
    return image;
  }
};

export const ItemTypes = {
  CARD: "card",
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

function ImageCard({ moveCard, url, index, id, handleRemoveImage }: any) {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId, isActive }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: any; isActive: boolean }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isActive:
          monitor.canDrop() &&
          monitor.isOver() &&
          !monitor.getItem().index === index,
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top;
      const hoverClientX = (clientOffset as any).x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // consider box dragging
      // dragging four directions
      // Dragging right
      // if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
      //   return;
      // }
      // // Dragging left
      // if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
      //   return;
      // }

      // // Dragging downwards
      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return;
      // }

      // // Dragging upwards
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return;
      // }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index, originalIndex: index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const { index, originalIndex } = item;
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveCard(index, originalIndex);
      }
    },
  });

  const opacity = isDragging ? 0 : isActive ? 0.5 : 1;
  drag(drop(ref));
  return (
    <div
      key={url}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className="relative w-[137px] h-[156px] bg-gray-400 rounded-lg overflow-hidden"
    >
      <img
        src={url}
        alt={`place-${index + 1}`}
        className="absolute top-0 left-0 object-cover w-full h-full rounded-lg"
      />
      <button
        onClick={() => handleRemoveImage(url)}
        className="absolute flex items-center justify-center w-6 h-6 p-1 bg-white rounded-full cursor-pointer top-2 right-2"
      >
        <CloseIcon className="[&_path]:stroke-black [&_path]:stroke-[2px]" />
      </button>
    </div>
  );
}

export function EditPlaceForm({
  open,
  onClose,
  place,
  refetch,
}: {
  open: boolean;
  onClose: (refetch?: boolean) => void;
  place?: any;
  refetch: (
    refetchId?: string,
    isDelete?: boolean,
    refetchAll?: boolean
  ) => void;
}) {
  const isNew = !place?.id;
  const { isLoading: isCategoryLoading, data: categoryData } = useQuery({
    queryKey: ["categories"],
    queryFn: () => api.getPlaceCategories({}),
  });

  const { isLoading: isActivitiesLoading, data: activityData } = useQuery({
    queryKey: ["place-activities"],
    queryFn: () => api.getPlaceActivities(),
  });

  const activities = activityData?.data || [];
  const categories = (categoryData?.data || []).sort((a, b) =>
    a.category_name.localeCompare(b.category_name)
  );

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [originalPlace, setOriginalPlace] = useState<any>({
    days_of_week: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
  });
  const [originalImages, setOriginalImages] = useState<string[]>(
    place?.images || []
  );
  const [placeForm, setPlaceForm] = useState<any>({
    days_of_week: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
    status: 1,
  });
  const [images, setImages] = useState<string[]>(place?.images || []);
  const [newFiles, setNewFiles] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);
  const [filesToDelete, setFilesToDelete] = useState<string[]>([]);

  const autoSaveData = useCallback((data) => {
    // console.log("Auto save", data);
    // handleSubmit(data, true);
    // handleSubmit()
  }, []);

  // A hook which triggers the save function every 2 seconds
  // This is useful for text fields where user can type and we can save the data at regular intervals
  const { updatePreviousData } = useAutoSave(
    autoSaveData,
    open ? placeForm : null
  );

  // When we have some selection or uploads data
  // that we can save directly without waiting for auto save
  /**
   * Fields that can be saved directly
   * 1. activity_images
   * 2. places
   */
  const handleAutoSave = (updatedData: any) => {
    const data = { ...placeForm, ...updatedData };
    console.log({ data });
    updatePreviousData(data);
    autoSaveData(data);
  };

  const handleUpload = async (files: any) => {
    if (!files || !files.length) return;
    const folderName = placeForm.id;
    const leftImageLength = 10 - images.length;
    const length =
      files.length > leftImageLength ? leftImageLength : files.length;
    if (!folderName) {
      const urls: string[] = [];
      const newImages: any[] = [];
      for (let i = 0; i < length; i++) {
        const file = files[i];
        const url = URL.createObjectURL(file);
        urls.push(url);
        newImages.push(file);
      }
      setNewFiles((prev) => [...prev, ...newImages]);

      setImages((prev) => [...prev, ...urls]);
      return;
    }
    try {
      setUploading(true);

      const urls: string[] = [];
      const leftImageLength = 10 - images.length;
      if (leftImageLength > 0) {
        const length =
          files.length > leftImageLength ? leftImageLength : files.length;
        for (let i = 0; i < length; i++) {
          const file = files[i];
          const imageResponse = await fileUpload(file, folderName);
          if (imageResponse?.url) {
            urls.push(imageResponse.url);
          }
        }
        if (urls.length) {
          setImages((prev) => [...prev, ...urls]);
          setPlaceForm((prev) => ({
            ...prev,
            uploaded_images: [...(prev.uploaded_images || []), ...urls],
          }));
        }
      }

      setUploading(false);
    } catch (error) {
      setUploading(false);

      console.log(error);
    }
  };

  // const handleNewFile = async (file) => {
  //   setFile(file);
  //   // upload image
  //   const folderName = placeForm.id;
  //   try {
  //     setUploading(true);
  //     const imageResponse = await fileUpload(file, folderName);
  //     setUploading(false);
  //     setFile(null);
  //     if (imageResponse.response.status === 200 && imageResponse?.url) {
  //       console.log(imageResponse?.url);
  //       setImages((prev) => [...prev, imageResponse?.url]);
  //       setPlaceForm((prev) => ({
  //         ...prev,
  //         uploaded_images: [
  //           ...(prev.uploaded_images || []),
  //           imageResponse?.url,
  //         ],
  //       }));
  //       // handleAutoSave({
  //       //   uploaded_images: [imageResponse?.url],
  //       //   images: [imageResponse?.url],
  //       // });
  //     }
  //   } catch (error) {
  //     setUploading(false);
  //     setFile(null);

  //     console.log(error);
  //   }
  // };

  const handleRemoveImage = (url) => {
    setImages((prevImages) => prevImages.filter((x) => x !== url));

    // if (placeForm?.images?.length > 0 && placeForm?.images?.includes(url)) {
    //   // Handle removal of an existing file
    //   const fileToDelete = placeForm.images[placeForm?.images?.indexOf(url)];
    //   if (fileToDelete) {
    //     setFilesToDelete((prev) => [...prev, fileToDelete]);
    //     // handleAutoSave({
    //     //   removal_images: [url],
    //     //   images: placeForm.images.filter((x) => x !== url),
    //     // });
    //     // Update displayed images
    //     setImages((prevImages) => prevImages.filter((x) => x !== url));
    //   }
    // }
  };

  const handleChange = (e: any) => {
    setPlaceForm({
      ...placeForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (placeForm: any, autoSave = false) => {
    if (!placeForm.display_name || !placeForm.formatted_address) {
      // !placeForm.type ||
      return;
    }
    const category = categories.find(
      (category) => category.id == placeForm.category
    )?.category_name;
    let payload: any = {
      id: placeForm.id,
      display_name: placeForm.display_name,
      formatted_address: placeForm.formatted_address,
      summary: placeForm?.summary || "",
      category,
      status: placeForm?.status,
      activities: placeForm?.activities || [],
      days_of_week: placeForm?.days_of_week,
      time_of_day: placeForm?.time_of_day,
      limited_time: placeForm?.limited_time,
      happening_now: placeForm?.happening_now,
      admin_notes: placeForm?.admin_notes || "",
      // removal_images: filesToDelete,
      // images,
    };
    if (placeForm.id) {
      payload.uploaded_images = images;
    } else {
      payload.images = [];
    }
    try {
      !autoSave && setSaving(true);
      // upload file
      // if (newFiles && newFiles.length > 0) {
      //   const folderName = placeForm.id;
      //   const uploadPromises = newFiles.map((item) => {
      //     return fileUpload(item.file, folderName).then((imageResponse) => {
      //       if (imageResponse.response.status === 200 && imageResponse?.url) {
      //         console.log(imageResponse?.url);
      //         return imageResponse?.url;
      //       }
      //       return null;
      //     });
      //   });

      //   const uploadedImageUrls = await Promise.all(uploadPromises);
      //   payload.uploaded_images = uploadedImageUrls.filter(
      //     (url) => url !== null
      //   );
      //   // update
      //   const response = await api.updatePlace(payload);
      //   setSaving(false);
      //   if (response.status === 200) {
      //     if (response?.data?.status) {
      //       setFilesToDelete([]);
      //       refetch("", false, true);
      //       onClose(true);
      //       ShowToast({
      //         type: "success",
      //         message: response?.data?.message,
      //       });
      //     } else {
      //       ShowToast({
      //         type: "error",
      //         message: response?.data?.message || "Something went wrong",
      //       });
      //     }
      //   } else {
      //     ShowToast({
      //       type: "error",
      //       message: response?.data?.message || "Something went wrong",
      //     });
      //   }
      // } else {
      // update
      const API = isNew ? api.createPlace : api.updatePlace;
      const response = await API(payload);
      setSaving(false);

      if (response.status === 200) {
        if (isNew) {
          // upload images
          setSaving(true);
          const folderName = response?.data?.data?.place_id;
          const uploadPromises = newFiles.map((image) => {
            return fileUpload(image, folderName);
          });
          const uploadedImages = await Promise.all(uploadPromises);
          console.log({ uploadedImages });
          const uploadedImageUrls = uploadedImages.map((image) => image?.url);
          payload.uploaded_images = uploadedImageUrls;
          payload.images = undefined;
          // update
          const updateResponse = await api.updatePlace({
            ...payload,
            id: response?.data?.data?.place_id,
            limited_time: {
              ends_on: "",
              is_checked: false,
            },
            happening_now: false,
          });
          setSaving(false);
        }
        if (response?.data?.status) {
          refetch("", false, true);
          if (!autoSave) {
            onClose(true);
            ShowToast({
              type: "success",
              message: response?.data?.message,
            });
          }
        } else {
          if (!autoSave) {
            ShowToast({
              type: "error",
              message: response?.data?.message || "Something went wrong",
            });
          }
        }
      } else {
        setSaving(false);
        if (!autoSave) {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
      // }
    } catch (e) {
      setSaving(false);
      if (!autoSave) {
        ShowToast({
          type: "error",
          message: "Failed to save",
        });
      }
    }
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setImages((images) => {
        // we need reorder files
        const prevCards = Array.from(images);
        const draggedCard = prevCards[dragIndex];
        prevCards.splice(dragIndex, 1);
        prevCards.splice(hoverIndex, 0, draggedCard);
        return prevCards;
      });
      if (isNew) {
        setNewFiles((prev) => {
          const prevNewFiles = Array.from(prev);
          const draggedFile = prevNewFiles[dragIndex];
          prevNewFiles.splice(dragIndex, 1);
          prevNewFiles.splice(hoverIndex, 0, draggedFile);
          return prevNewFiles;
        });
      }
    },
    [isNew]
  );

  // Update images state when the file changes
  useEffect(() => {
    // if (file) {
    //   const newImageUrl = URL.createObjectURL(file);
    //   setNewFiles((prevFiles) => [
    //     ...prevFiles,
    //     { fileName: newImageUrl, file: file },
    //   ]);
    //   setImages((prevImages) => [...prevImages, newImageUrl]);
    // }
  }, [file]);

  const fetchPlace = async (place: any) => {
    try {
      setLoading(true);
      const response = await api.getPlace(place.id);
      const category = categories.find(
        (category) =>
          category.category_name === (response.data?.category || place.category)
      )?.id;
      setPlaceForm({ ...response.data, category } || { ...place, category });
      // updatePreviousData(
      //   { ...response.data, category } || { ...place, category }
      // );
      setImages(response?.data?.images || []);
      setOriginalPlace(
        { ...response.data, category } || { ...place, category }
      );
      setOriginalImages(response?.data?.images || []);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (open && place?.id) {
      fetchPlace(place);
    }
  }, [open, place]);

  const isEdit = !!place?.id;

  const isValidate = () => {
    return (
      placeForm.display_name && placeForm.formatted_address
      // placeForm.category &&
      // placeForm.activities?.length > 0 &&
      // placeForm.status &&
      // placeForm?.time_of_day?.length > 0 &&
      // anyTrue(placeForm?.days_of_week)
    );
  };

  const isObjectChanged = useMemo(() => {
    // check if the object has changed
    return (
      JSON.stringify(originalPlace) !== JSON.stringify({ ...placeForm, images })
    );
  }, [placeForm, images, originalImages, originalPlace]);

  const renderUploadTile = () => {
    if (images?.length < 12) {
      return (
        <div className="relative w-[137px] h-[156px] bg-gray-200 rounded-lg flex items-center justify-center">
          <UploadPicture
            image={file ? URL.createObjectURL(file) : undefined}
            uploading={uploading}
            onChange={(files) => handleUpload(files)}
          />
        </div>
      );
    }
    return null;
  };

  const handleOnClose = () => {
    // setNewFiles([]);
    setFilesToDelete([]);
    onClose();
  };

  useEffect(() => {
    if (open) {
      setFilesToDelete([]);
    }
  }, [open]);

  if (!open) return null;
  return (
    <DndProvider backend={HTML5Backend}>
      <FullModal open={open} onClose={handleOnClose}>
        {loading ? (
          <div className="h-[calc(100vh-100px)] flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className="flex flex-col mx-auto">
              <div className="flex justify-end mb-2">
                <Button
                  variant="primary"
                  className="!rounded-full !px-10 !py-3 !text-base !font-bold text-white"
                  onClick={() => handleSubmit(placeForm)}
                  disabled={saving || !isValidate() || !isObjectChanged}
                >
                  {isEdit ? "Save" : "Add"}
                  {saving && <Loading />}
                </Button>
              </div>
              <div className="h-[calc(100vh-100px)] overflow-auto flex flex-row justify-between gap-6 items-start bg-white rounded-[16px] p-6">
                <div className="flex flex-col gap-4">
                  <div className="grid grid-cols-3 gap-4">
                    {images?.map((imageUrl, index) => (
                      <ImageCard
                        url={imageUrl}
                        key={imageUrl}
                        index={index}
                        id={imageUrl}
                        handleRemoveImage={handleRemoveImage}
                        moveCard={moveCard}
                      />
                    ))}

                    {images?.length < 10 && renderUploadTile()}
                  </div>
                  {!isNew && (
                    <>
                      <OpeningHours opening_hours={placeForm.opening_hours} />
                      <div className="text-sm font-semibold">Google Info</div>
                      <div>
                        <iframe
                          src={`${placeForm.map_uri}&output=embed`}
                          height="280"
                          allowFullScreen
                          loading="lazy"
                          title="Map"
                          className="w-full border rounded-md border-black/20"
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className=" w-[380px] flex flex-col gap-7 h-full">
                  <div className="flex flex-col gap-2">
                    <label className="text-sm font-semibold text-black">
                      Place Name
                    </label>
                    <TextInput
                      name="display_name"
                      value={placeForm.display_name}
                      onChange={handleChange}
                      placeholder="Enter place name"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-sm font-semibold text-black">
                      Address
                    </label>
                    <TextInput
                      name="formatted_address"
                      value={placeForm.formatted_address}
                      onChange={handleChange}
                      placeholder="Enter address"
                    />
                  </div>
                  {!isNew && (
                    <div className="flex flex-col gap-2">
                      <label className="text-sm font-semibold text-black">
                        Experiences
                      </label>
                      <div className="pl-2 pr-3 py-2.5 opacity-80 bg-white rounded-md border border-[#8D8E92] justify-start items-center gap-2.5 flex flex-wrap">
                        {placeForm.experiences?.map((activity: any) => {
                          return (
                            <a
                              href={`/experiences?id=${activity?.id}`}
                              className="flex items-center justify-start gap-1 px-2 py-1 border rounded bg-neutral-100 border-black/5"
                            >
                              {activity?.experience_name}
                            </a>
                          );
                        })}
                      </div>
                      {/* <MultiSelect
                      options={[
                        ...activities.map((a) => ({
                          label: a.activity,
                          value: a.activity,
                        })),
                      ]}
                      value={placeForm.activities || []}
                      name="activities"
                      onChange={(selectedOptions) => {
                        setPlaceForm((prev: any) => ({
                          ...prev,
                          activities: selectedOptions.map((o) => o.value),
                        }));
                      }}
                    /> */}
                    </div>
                  )}
                  {/* <div className="flex-1 flex gap-3.5 items-center">
                    <div className="flex-1">
                      <label className="text-sm font-semibold text-black">
                        Category
                      </label>
                      <SelectInput
                        className="!py-2.5 mt-2"
                        data={categories.map((category) => ({
                          label: category.category_name,
                          value: category.id,
                        }))}
                        onChange={(e) => {
                          setPlaceForm((prev) => ({
                            ...prev,
                            category: e.target.value,
                          }));
                        }}
                        value={placeForm.category}
                      />
                    </div>
                    <div className="flex-1">
                      <label className="text-sm font-semibold text-black">
                        Status
                      </label>
                      <SelectInput
                        className="!py-2.5 mt-2"
                        data={statusList}
                        value={placeForm.status}
                        onChange={(e) => {
                          setPlaceForm((prev) => ({
                            ...prev,
                            status: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div> */}
                  <DaysOfWeek
                    onChange={(field, value) =>
                      setPlaceForm((prev) => ({
                        ...prev,
                        [field]: value,
                      }))
                    }
                    value={placeForm.days_of_week}
                    limitedTime={placeForm.limited_time || {}}
                  />
                  {/* <div className="flex items-center gap-2 mr-3">
                    <input
                      type="checkbox"
                      id="place"
                      name="place"
                      className="w-5 h-5 border border-[#8D8E92] accent-black rounded-md"
                      checked={placeForm?.happening_now}
                      onChange={() =>
                        setPlaceForm((prev) => ({
                          ...prev,
                          happening_now: !placeForm?.happening_now,
                        }))
                      }
                    />
                    <span className="text-[#1D1E1B] text-sm">
                      Happening Now Featured
                    </span>
                  </div> */}
                  <TimeOfDay
                    onChange={(field, val) =>
                      setPlaceForm((prev) => ({
                        ...prev,
                        [field]: val,
                      }))
                    }
                    value={placeForm.time_of_day || []}
                  />
                  <div>
                    <div className="mb-2 text-sm font-semibold text-black">
                      Place Description
                    </div>
                    <TextArea
                      value={placeForm.summary || ""}
                      onChange={(e) =>
                        setPlaceForm((prev) => ({
                          ...prev,
                          summary: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <div className="mb-2 text-sm font-semibold text-black">
                      Admin Notes
                    </div>
                    <TextArea
                      value={placeForm.admin_notes || ""}
                      onChange={(e) =>
                        setPlaceForm((prev) => ({
                          ...prev,
                          admin_notes: e.target.value,
                        }))
                      }
                    />
                  </div>
                  {/* <div className="flex flex-col gap-1.5">
                  <label className="text-sm opacity-80">Blog Count</label>
                  <TextInput
                    name="blog_count"
                    value={placeForm.blog_count}
                    onChange={handleChange}
                    placeholder="Enter blog count"
                  />
                </div>
                <div className="flex flex-col gap-1.5">
                  <label className="text-sm opacity-80">Price</label>
                  <SelectInput
                    name="price_level"
                    data={Prices}
                    value={priceMapper(placeForm.price_level)}
                    onChange={handleChange}
                    placeholder="Enter price"
                  />
                </div>
                <div className="flex flex-col gap-1.5 flex-1 [&>div]:flex-1">
                  <label className="text-sm opacity-80">AI Summary</label>
                  <TextArea
                    cols={6}
                    name="summary"
                    value={placeForm.summary}
                    onChange={handleChange}
                    placeholder="Enter summary"
                    className="h-full"
                  />
                </div> */}
                </div>
              </div>
            </div>
          </>
        )}
      </FullModal>
    </DndProvider>
  );
}
