import { SideModal } from "../../../components/Modals/SideModal";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "../../../assets/icons/minus.svg";

import { useEffect, useMemo, useState } from "react";
import { TextInput } from "../../../components/TextInput";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { Button } from "../../../components/Button";
import { frequencies } from "./RecommendationFrequency";
import { SelectableChip } from "./SelectableChip";
import { CategorySelect } from "./CategorySelect";
import { statusList } from "./ActivityForm";

function Accordion({ setFormFilters, value, label, type = "number", field }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // if already have value, set isOpen to true
    if (value[`${field}_min`] || value[`${field}_max`]) {
      setIsOpen(true);
    }
  }, [value, field]);

  return (
    <div className="p-3.5 bg-white rounded-lg border border-[#d9d9e6] flex-col justify-start items-start gap-3 inline-flex">
      <button
        type="button"
        className="self-stretch justify-between items-center flex"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="opacity-80 text-black text-sm font-normal  leading-[17.92px]">
          {label}
        </div>
        {isOpen ? (
          <MinusIcon className="w-5 h-5" />
        ) : (
          <PlusIcon className="w-5 h-5 [&_path]:stroke-black" />
        )}
      </button>
      {isOpen && (
        <>
          {type === "number" && (
            <div className="p-[13px] gap-[10px] w-full bg-white rounded-lg border border-[#d9d9e6] justify-between items-center flex">
              <>
                <div className="flex flex-col gap-[10px]">
                  <label className="text-[#1d1e1b] text-sm font-normal">
                    Min
                  </label>
                  <TextInput
                    type={type}
                    placeholder="e.g. 10"
                    onChange={(e) =>
                      setFormFilters((prev) => ({
                        ...prev,
                        [`${field}_min`]: e.target.value,
                      }))
                    }
                    value={value[`${field}_min`] || ""}
                    className="!py-3"
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label className="text-[#1d1e1b] text-sm font-normal">
                    Max
                  </label>
                  <TextInput
                    type={type}
                    placeholder="e.g. 100"
                    onChange={(e) =>
                      setFormFilters((prev) => ({
                        ...prev,
                        [`${field}_max`]: e.target.value,
                      }))
                    }
                    value={value[`${field}_max`] || ""}
                    className="!py-3"
                  />
                </div>
              </>
            </div>
          )}
          {type === "date" && (
            <div className="flex gap-2.5 items-center w-full">
              <div className="flex flex-col gap-[10px] w-full flex-1">
                <DatePicker
                  onChange={(e) =>
                    setFormFilters((prev) => ({
                      ...prev,
                      [`${field}_start`]: e,
                    }))
                  }
                  value={value[`${field}_start`]}
                  className="!py-2"
                />
              </div>
              <div className="flex flex-col gap-[10px] w-full flex-1">
                <DatePicker
                  onChange={(e) =>
                    setFormFilters((prev) => ({
                      ...prev,
                      [`${field}_end`]: e,
                    }))
                  }
                  value={value[`${field}_end`]}
                  className="!py-2"
                />
              </div>
            </div>
          )}
          {type === "date_added_on" && (
            <div className="flex gap-2.5 items-center w-full">
              <div className="flex flex-col gap-[10px] w-full flex-1">
                <DatePicker
                  onChange={(e) =>
                    setFormFilters((prev) => ({
                      ...prev,
                      date_added_on: e,
                    }))
                  }
                  value={value.date_added_on}
                  className="!py-2"
                />
              </div>
            </div>
          )}
          {type === "frequency" && (
            <div className="flex gap-2 items-center">
              {frequencies.map((frequency) => (
                <SelectableChip
                  label={frequency.label}
                  selected={value.frequencies.includes(frequency.value)}
                  onClick={() => {
                    setFormFilters((prev) => {
                      if (prev.frequencies.includes(frequency.value)) {
                        return {
                          ...prev,
                          frequencies: prev.frequencies.filter(
                            (f) => f !== frequency.value
                          ),
                        };
                      } else {
                        return {
                          ...prev,
                          frequencies: [...prev.frequencies, frequency.value],
                        };
                      }
                    });
                  }}
                />
              ))}
            </div>
          )}
          {type === "category" && (
            <div className="w-full [&>div]:!w-full">
              <CategorySelect
                onChange={(e) =>
                  setFormFilters((prev) => ({
                    ...prev,
                    category: e,
                  }))
                }
                value={value.category || []}
                showLabel={false}
              />
            </div>
          )}
          {type === "status" && (
            <div className="flex items-center justify-between gap-2">
              {statusList.map((status) => (
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    value={status.value}
                    id={`status-${status.value}`}
                    checked={value.status?.includes(status.value)}
                    onChange={(e) => {
                      const filter_value = [...(value.status || [])];
                      if (!e.target.checked) {
                        if (filter_value.includes(status.value)) {
                          filter_value.splice(
                            filter_value.indexOf(status.value),
                            1
                          );
                        }
                      } else {
                        filter_value.push(status.value);
                      }
                      console.log(filter_value);
                      setFormFilters((prev) => ({
                        ...prev,
                        status: [...filter_value],
                      }));
                    }}
                    className="w-5 h-5 cursor-pointer accent-black"
                  />
                  <label
                    htmlFor={`status-${status.value}`}
                    className="cursor-pointer"
                  >
                    {status.label}
                  </label>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export function ExperienceFilters({
  isOpen,
  onClose,
  filter,
  onChange,
}: {
  isOpen: boolean;
  onClose: () => void;
  filter: {
    received_count_min: string;
    received_count_max: string;
    date_start: string;
    date_end: string;
    skipped_count_min: string;
    skipped_count_max: string;
    accepted_count_min: string;
    accepted_count_max: string;
    status: string[];
  };
  onChange: (filters: any) => void;
}) {
  const [formFilters, setFormFilters] = useState<any>(filter);
  console.log(formFilters.status, filter.status);

  useEffect(() => {
    setFormFilters(filter);
  }, [filter]);

  const disabled = useMemo(() => {
    let flag = false;
    [
      "suggested_count_min",
      "suggested_count_max",
      "skipped_count_min",
      "skipped_count_max",
      "accepted_count_min",
      "accepted_count_max",
    ].forEach((field) => {
      if (formFilters[field]) {
        // compare with original filter
        if (formFilters[field] !== filter[field]) {
          flag = true;
        }
      }
    });

    ["frequencies", "category", "status"].forEach((key) => {
      const newLength = formFilters[key].length;
      const oldLength = filter[key].length;
      console.log(newLength, oldLength, key);
      if (newLength !== oldLength) {
        flag = true;
      } else {
        // check if the values are different
        // ["1", "2", "3"], new: ["3", "4", "5"]
        formFilters[key].forEach((value) => {
          if (!filter[key].includes(value)) {
            flag = true;
          }
        });
      }
    });

    ["date_start", "date_end", "date_added_on"].forEach((key) => {
      if (filter[key] !== formFilters[key]) {
        flag = true;
      }
    });
    return flag;
  }, [formFilters, filter]);

  const onClear = () => {
    setFormFilters({
      accepted_count_max: "",
      accepted_count_min: "",
      suggested_count_max: "",
      suggested_count_min: "",
      skipped_count_max: "",
      skipped_count_min: "",
      category: [],
      frequencies: [],
      status: [],
      date_start: "",
      date_end: "",
      date_added_on: "",
    });
  };

  const onApply = () => {
    onClose();
    onChange({ filter: { ...formFilters } });
  };

  const handleClose = () => {
    onClose();
    setFormFilters(filter);
  };

  console.log(filter, formFilters);
  return (
    <SideModal onClose={handleClose}>
      <div className="h-full w-full lg:w-[450px] relative bg-white flex flex-col">
        <div className="w-full px-5 py-[18px] border-b border-neutral-200 justify-between items-center flex">
          <div className="text-neutral-800 text-xl font-bold  leading-snug">
            Filters
          </div>
          <button type="button" onClick={handleClose}>
            <CloseIcon className="w-6 h-6 relative" />
          </button>
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div>
            <div className="p-4 flex flex-col gap-[14px] max-h-[calc(100vh-150px)] overflow-y-auto">
              <Accordion
                label="Date"
                setFormFilters={setFormFilters}
                value={formFilters}
                type="date"
                field="date"
              />
              <Accordion
                label="Date Added On"
                setFormFilters={setFormFilters}
                value={formFilters}
                type="date_added_on"
                field="date_added_on"
              />
              <Accordion
                label="Hashtags"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="category"
                type="category"
              />
              <Accordion
                label="Frequency"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="frequencies"
                type="frequency"
              />
              <Accordion
                label="Status"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="status"
                type="status"
              />
              <Accordion
                label="Suggested"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="suggested_count"
                type="number"
              />
              <Accordion
                label="Accepted"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="accepted_count"
                type="number"
              />
              <Accordion
                label="Skipped"
                setFormFilters={setFormFilters}
                value={formFilters}
                field="skipped_count"
                type="number"
              />
            </div>
          </div>

          <div className="px-5 py-3 border-t border-neutral-200 flex justify-end">
            <Button
              variant="link"
              className="!text-[#00C88C] hover:text-[#00C88C]/80 !text-base !font-medium"
              onClick={onClear}
            >
              Clear
            </Button>

            <Button
              disabled={!disabled}
              className="!px-10 !py-3 !rounded-full"
              onClick={onApply}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </SideModal>
  );
}
