import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { LoadAnimatedContainer } from "../AnimatedContainer";
import { useEffect } from "react";

const dropIn = {
  hidden: {
    y: "100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.25,
      // type: "spring",
      // stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
    transition: {
      duration: 0.25,
      // type: "spring",
      // stiffness: 500,
    },
  },
};

const newspaper = {
  hidden: {
    transform: "scale(0) rotate(720deg)",
    opacity: 0,
    transition: {
      delay: 0.3,
    },
  },
  visible: {
    transform: " scale(1) rotate(0deg)",
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    transform: "scale(0) rotate(-720deg)",
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

export function FullModal({
  children,
  onClose,
  open,
  contentClassName,
  showCloseButton = true,
  modalClassName = "",
}: {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  contentClassName?: string;
  showCloseButton?: boolean;
  modalClassName?: string;
}) {
  useEffect(() => {
    // add event listener to close modal on escape key press
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    if (open) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }
    return () => document.removeEventListener("keydown", handleEscape);
  }, [open]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`fixed inset-0 z-50 overflow-y-auto backdrop-blur-sm ${modalClassName} `}
    >
      <AnimatePresence mode="sync">
        {open && (
          <motion.div
            key="modal"
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={(e) => e.stopPropagation()}
            className="flex items-center justify-center min-h-screen relative"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40" />
            <div
              className={`relative bg-background z-[1] mt-6 h-[calc(100vh-24px)] w-full px-5 pb-5 pt-4 flex flex-col ${contentClassName}`}
            >
              {showCloseButton && (
                <button
                  className="absolute right-3 top-4"
                  onClick={onClose}
                  aria-label="Close"
                >
                  <CloseIcon className="w-5 h-5" />
                </button>
              )}
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
