import { useEffect, useState } from "react";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location-pin.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-2.svg";
import { ReactComponent as ImportIcon } from "../../../assets/icons/import.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { priceMapper } from "../../../helpers/priceMapper";
import moment from "moment";
import { Tooltip } from "react-tooltip";

export function ImportedTooltip({ place, prefix = "" }) {
  return (
    <>
      <div
        className="bg-[#C1F5C3] flex justify-center items-center p-1 w-6 h-6 rounded-full"
        data-tooltip-id={`${prefix}my-tooltip-${place.id}`}
      >
        <ImportIcon className={`w-[14px] h-[14px]`} />
      </div>

      <Tooltip
        id={`${prefix}my-tooltip-${place?.id}`}
        className="!w-[200px] z-[2] !border border-[#D6D6D6] !text-black !rounded-md !p-[11px] !bg-white !shadow-[0px_4px_6px_0px_rgba(0,0,0,0.12)]"
        noArrow
      >
        <div className="flex text-[13px] text-[#202020] flex-col gap-1">
          <div>Already imported on </div>
          <div>{moment(place?.date_imported).format("MMM DD, YYYY")}</div>
        </div>
      </Tooltip>
    </>
  );
}

function ListItem({ place, onEdit, onRemove }) {
  return (
    <div className="flex justify-between gap-2.5 p-4 rounded-md border border-[#DDD]">
      <div>
        <div className="flex items-center gap-2">
          <div className="text-[#2A2B2F] text-sm font-semibold mb-2">
            {place?.displayName?.text || place?.name}
          </div>
          {place.is_existing && <ImportedTooltip place={place} />}
        </div>
        <div className="text-[#555] text-sm flex items-start gap-[3px]">
          <LocationIcon className="w-[18px] h-[18px] mt-1" />{" "}
          <span className="flex-1">
            {place?.formattedAddress || place?.address}
          </span>
        </div>
      </div>
      <div className="flex items-center gap-1.5 ">
        <button
          onClick={onEdit}
          disabled={!place.is_existing}
          className={`${!place.is_existing ? "opacity-30" : ""}`}
        >
          <EditIcon className="[&_path]:fill-[#710CC0]" />
        </button>
        <button onClick={() => onRemove(place)}>
          <TrashIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
}

export function AddedPlaces({
  selectedPlaces,
  onRemove,
  onEdit,
}: {
  selectedPlaces: any[];
  onRemove: any;
  onEdit: any;
}) {
  return (
    <div className="selected-places relative my-2.5 mx-3">
      <div className="overflow-auto h-[800px] px-[18px] py-1.5 gap-2.5 flex flex-col">
        {selectedPlaces.map((place) => (
          <ListItem
            key={place.id}
            place={place}
            onRemove={() => onRemove(place)}
            onEdit={() => onEdit(place)}
          />
        ))}
      </div>
    </div>
  );
}
